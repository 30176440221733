import React from 'react'
import { PageHeader } from '@ant-design/pro-layout'
import { HomeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import ScreenZoom from '../ScreenZoom'

function Page({ title, subTitle, routes, children, extra }) {
  const reRoutes =
    routes?.map((x) => {
      return { ...x, breadcrumbName: x.name }
    }) || []

  return (
    <PageHeader
      title={title}
      subTitle={subTitle}
      extra={extra}
      breadcrumb={{
        routes: [
          {
            path: '/',
            name: 'Anasayfa',
            breadcrumbName: 'Anasayfa',
            icon: <HomeOutlined />,
          },
          ...reRoutes,
        ],
        seperator: '>',
        itemRender: (r) =>
          r.path ? (
            <Link to={r.path}>
              {r.icon}
              <span> {r.name}</span>
            </Link>
          ) : (
            r.name
          ),
      }}
    >
      <ScreenZoom />
      {children}
    </PageHeader>
  )
}

export default Page