export const customCleanHTML = (input) => {
    let output;
    if(input.includes('<table'))
        output = input.replace(/<\s*([a-z][a-z0-9]*)\s.*?>/gi, '<$1>').replace(/<[^>]p*>/g, "").replace(/&nbsp;/ig, "");
    else
        output = input.replace(/<\s*([a-z][a-z0-9]*)\s.*?>/gi, '<$1>').replace(/&nbsp;/ig, "");

    return output;
};

export const removeStylesHTML = (input) => {
    if(input === null || input === undefined)
        return input;

    return input.replace(/style="[a-zA-Z0-9:;.\s()\-,]*"/gi,'');
}


