import dayjs from "dayjs"
import { Switch } from "antd"
import Button from "../../components/Button"
import { EditOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

export default function emailColumns(edit, activeChanged) {
    return [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 90,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => { }
        },
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.code?.localeCompare(b.code, 'tr', { sensivity: 'base' })
        },
        {
            title: 'Şablon Adı',
            dataIndex: 'name',
            key: 'name',
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.name?.localeCompare(b.name, 'tr', { sensivity: 'base' })
        },
        {
            title: 'Değiştirilme Tarihi',
            dataIndex: 'lastModificationTime',
            key: 'lastModificationTime',
            width: 220,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => { },
            render: v => dayjs(v).format("LLL")
        },
        {
            title: 'Değiştiren',
            dataIndex: 'lastModifierUser',
            key: 'lastModifierUser',
            width: 220,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.lastModifierUser?.localeCompare(b.lastModifierUser, 'tr', { sensivity: 'base' })
        },
        {
            title: "Aktif / Pasif",
            fixed: "right",
            width: 110,
            dataIndex: "isActive",
            align: "center",
            render: (v, row) => <>
                <Switch style={{ margin: '15px 0' }} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={v} loading={row.loading} onChange={val => activeChanged(val, row)} />
            </>
        },
        {
            title: "Düzenle",
            fixed: "right",
            width: 90,
            render: (v, row) => <>
                <Button key="edit" type="primary" onClick={() => edit(row)}><EditOutlined /></Button>
            </>
        }
    ]
}