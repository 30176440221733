import qs from 'qs'
import client from '../../configs/axios'

const DEFAULT_QUERY = '/users'

class UserService {
  login = (model) =>
    client.post(`${DEFAULT_QUERY}/login`, qs.stringify(model), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    })
  otpLogin = (model) =>
    client.post(`${DEFAULT_QUERY}/otp/login`, qs.stringify(model), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      },
    })
  otp = (model) => client.post(`${DEFAULT_QUERY}/otp`, model)
  register = (model) => client.post(`${DEFAULT_QUERY}/register`, model)
  forgotPassword = (mailAddress) =>
    client.post(`${DEFAULT_QUERY}/forgot-password`, { mailAddress })
  delete = (model) => client.delete(`${DEFAULT_QUERY}/`, { data: model })
  getAll = (params) => client.get(`${DEFAULT_QUERY}/`, { params })
  update = (model) => client.put(`${DEFAULT_QUERY}/`, model)
  updatePassword = (model) => client.put(`${DEFAULT_QUERY}/password`, model)
  detail = (userId) => client.get(`${DEFAULT_QUERY}/${userId}/detail`)
  async me(token = null) {
    token = token || JSON.parse(localStorage.getItem('user'))?.access_token
    let req = await client.get(`${DEFAULT_QUERY}/me`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    let _model = {
      access_token: token,
      profile: {
        name: `${req.data.firstName} ${req.data.lastName}`,
        firstName: req.data.firstName,
        lastName: req.data.lastName,
        companyName: req.data.companyName,
        companyId: req.data.companyId,
      },
      permissions: req.data.permissions,
    }

    localStorage.setItem('user', JSON.stringify(_model))
    return _model
  }
}

export default UserService
