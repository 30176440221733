import React, {useEffect} from 'react'

import Page from "../../components/layout/Page"
import StoragePanel from "./StoragePanel"
import {useCustomQueryStringOperations} from "../../hooks/useCustomQueryStringOperations";

export default function Storages(params) {

    const [, , ,customHistoryReplace] = useCustomQueryStringOperations({}, "", "storage");

    useEffect(() => {
        customHistoryReplace();
    }, [])

    return <Page title="Depolama">
        <StoragePanel pageType="page" />
    </Page>
}
