import React, { useState } from 'react'
import { Form, Row, Col, Input, Button } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import Editor from "../../components/Editor"

export default function EmailEdit({ formRef, onSubmit, ready }) {
    const [visibleParameter, setVisibleParameter] = useState(false)

    return <>
        <Form form={formRef} layout="vertical" onFinish={onSubmit} size="large">
            <Form.Item name="isActive" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            {formRef.getFieldValue("id") === undefined &&
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="code"
                            label="Şablon Kodu"
                            rules={[{ required: true, message: 'Lütfen şablon kodu yazınız' }]}
                        >
                            <Input placeholder="Lütfen şablon kodunu yazınız" />
                        </Form.Item>
                    </Col>
                </Row>
            }
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Şablon Adı"
                        rules={[{ required: true, message: 'Lütfen şablon adını yazınız' }]}
                    >
                        <Input placeholder="Lütfen şablon adını yazınız" />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={24} >
                    <Form.Item
                        name="body"
                        className="content-label"
                        label={<><span>İçerik</span> <Button className="parameter" onClick={() => setVisibleParameter(true)} type="link">Parametre kullan</Button></>}
                        rules={[{ required: true, message: 'Lütfen içeriği giriniz' }]}
                    >
                        <Editor onChange={data => formRef.setFieldsValue({ body: data })} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button htmlType="submit" type="primary" style={{ width: "100%", marginTop: 20 }} onClick={() => formRef.submit()} disabled={!ready} loading={!ready}><SaveOutlined />Kaydet</Button>
                </Col>
            </Row>
        </Form>

    </>
}