import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { getAssetsUrl } from '../../configs/origins'

export default function LoginContainer({ children }) {
  return (
    <Container>
      <MidContainer>
        <Logo
          src={`/assets/image/logo.png`}
          marginBottom="40"
          width="160"
        />
        {children}
      </MidContainer>
      <Row>
        <Col span={24}>
          <BottomLinkGroup>
            <BottomLink href="#">Kullanım koşulları</BottomLink>
            <span>•</span>
            <BottomLink href="#"> Gizlilik politikası</BottomLink>
          </BottomLinkGroup>
        </Col>
        <Col span={24}>
          <BottomText>Copyright Greysis Teknoloji A.Ş. All rights reserved.</BottomText>
        </Col>
      </Row>
    </Container>
  )
}

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 20px;
  min-height: 500px;
`
const MidContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -30px;
  max-width: 400px;
  padding-bottom: 20px;
`

const BottomLinkGroup = styled.div`
  text-align: center;
  & {
    font-size: 13px;
  }
`

const BottomLink = styled.a`
  margin: 3px 5px;
  font-size: 10px;
  color: #3ea3fc;
`
const BottomText = styled.p`
  font-size: 12px;
  text-align: center;
`
const Logo = styled.img`
  width: ${(props) => props.width || 250}px;
  margin-bottom: ${(props) => props.marginBottom || 20}px;
`
