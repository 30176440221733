const scheduledSmsTypes = [
  {
    name: 'Ev',
    value: 'Home'
  },
  {
    name: 'Kurumsal',
    value: 'Corporate'
  },
  {
    name: 'İş Yeri',
    value: 'Business'
  },
  {
    name: 'Landing Ev',
    value: 'LandingHome'
  },
  {
    name: 'Landing Kurumsal',
    value: 'LandingCorporate'
  },
  {
    name: 'Landing İş Yeri',
    value: 'LandingBusiness'
  },
  {
    name: 'SKTT',
    value: 'Sktt'
  },
  {
    name: 'Kredi Başvuruları',
    value: 'Credit'
  },
  {
    name: 'Sigorta Başvuruları',
    value: 'Insurance'
  },
  {
    name: 'Team',
    value: 'Team'
  },
]

export default scheduledSmsTypes