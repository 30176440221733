import styled, { css } from 'styled-components'

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  background: #fcfcfc;
`
export const Header = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 14px;

  width: 100%;
  height: 65px;

  background: #f0f6ff;

  @media only screen and (min-width: 425px) {
    padding: 10px 48px;
  }

  //Datepicker
  .ant-space-item {
    width: 100%;
    height: 100%;
  }
  .ant-picker {
    margin: 0 !important;
    padding: 0 10px;

    width: 100%;
    max-width: 100%;
    height: 44px;

    background-color: #fff;
    border: 0;
    border-radius: 6px;

    font-size: 12px;
    text-align: left;
    color: #5c5c5c;

    @media only screen and (min-width: 1024px) {
      max-width: 223px;
    }
  }
  .ant-picker-input > input {
    font-size: 12px;
    color: #5c5c5c;
  }
  .ant-picker-suffix {
    width: 24px;
    min-width: 24px;
    height: 24px;

    background: transparent url('/assets/svg/calendar.svg') no-repeat center center;
  }
  .ant-picker-range .ant-picker-active-bar {
    border-radius: 2px;
    background-color: #3e73df;
  }
  .ant-picker-range .ant-picker-clear {
    top: 53%;
    right: 16px;

    color: #5c5c5c;
  }
`
export const FilterRange = styled.div`
  display: none;
  justify-content: flex-start;
  align-items: center;

  margin-left: 32px;

  width: 100%;
  height: 45px;

  @media only screen and (min-width: 1024px) {
    display: flex;
  }
`
export const Button = styled.button`
  position: relative;
  display: inline-block;

  padding: 0 12px;

  height: 45px;

  background: transparent;
  border: 0;

  font-size: 13px;
  font-weight: 600;
  color: #4d5b82;

  cursor: pointer;

  &::after {
    content: none;
    position: absolute;
    bottom: -11px;
    left: 0;

    display: inline-block;

    width: 100%;
    height: 2px;

    border-radius: 2px;
    background-color: #3e73df;
  }

  ${({ active }) =>
    active &&
    css`
      color: #081f50;
      &::after {
        content: '';
      }
    `}
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 14px;

  width: 100%;

  background: #fcfcfc;

  @media only screen and (min-width: 425px) {
    padding: 35px 48px;
  }
`
export const ContentHeader = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-bottom: 48px;

  width: 100%;
`
export const Title = styled.h1`
  display: inline-block;

  margin: 0;

  width: 100%;

  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  color: #081f50;

  @media only screen and (min-width: 425px) {
    font-size: 34px;
  }
`
export const Desc = styled.p`
  display: inline-block;

  margin: 0;

  width: 100%;

  font-size: 12px;
  font-weight: 300;
  line-height: 14px;
  color: #081f50;
`
export const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;

  margin-left: -12px;

  width: calc(100% + 24px);
`
export const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin: 12px;
  padding: 21px;

  width: 100%;
  max-width: calc((100% / 1) - 24px);
  min-height: 400px;

  background-image: url('/assets/image/dashboard.png');
  background-repeat: no-repeat;
  background-size: 1344px auto;
  background-position: bottom left;
  background-color: #85B9B6;
  border-radius: 12px;

  color: #fff;

  @media only screen and (min-width: 768px) {
    max-width: calc((100% / 2) - 24px);
  }
  @media only screen and (min-width: 1024px) {
    max-width: calc((100% / 4) - 24px);
  }

  ${(props) =>
    props.fluid &&
    css`
      flex-direction: row;
      flex-wrap: wrap;

      max-width: 100%;
      min-height: 119px;
      
      background-color: #9DACB0;
      background-position: center left;
      background-size: cover;

      @media only screen and (min-width: 768px) {
        max-width: 100%;
      }
      @media only screen and (min-width: 1024px) {
        max-width: 100%;
      }
    `}

  &:nth-child(2) {
    background-position: bottom left calc(25% - 42px);
    background-color: #849BD7;
  }
  &:nth-child(3) {
    background-position: bottom left calc(50% - 84px);

  }
  &:nth-child(4) {
    background-position: bottom right;
    background-color: #849BD7;
  }
`
export const DetailButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;

  width: 58px;
  height: 44px;

  background: transparent url('/assets/svg/dotted.svg') no-repeat center center;
  border: 0;

  cursor: pointer;
`
export const BoxTitle = styled.h2`
  display: inline-block;

  margin: 0;

  width: 100%;

  font-size: 24px;
  line-height: 28px;
  font-weight: 600;
  color: #fff;
`
export const BoxDesc = styled.div`
  display: inline-block;

  margin: 0;
  width: 100%;

  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
`
export const BoxData = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 21px;

  width: 100%;
  ${(props) =>
    props.dark &&
    css`
      color: #081f50;
    `}
  h2 {
    display: inline-block;

    margin: 0;

    width: 100%;

    font-size: 52px;
    font-weight: 700;
    line-height: 61px;
    color: #fff;
    ${(props) =>
      props.dark &&
      css`
        color: #081f50;
      `}
  }
  p {
    display: inline-block;

    margin: 0;

    width: 100%;

    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    ${(props) =>
      props.dark &&
      css`
        color: #081f50;
      `}
  }
`
export const BoxList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 21px;

  width: 100%;

  h3 {
    display: inline-block;

    margin: 0;

    width: 100%;

    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    color: #fff;
    ${(props) =>
      props.dark &&
      css`
        color: #081f50;
      `}
  }
  ul {
    margin-top: 14px;
    padding: 0;

    width: 100%;

    list-style: none;

    li {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      width: 100%;
      height: 34px;

      border-bottom: 1px solid rgba(255, 255, 255, 0.34);
      ${(props) =>
        props.dark &&
        css`
          border-bottom: 1px solid rgba(221, 221, 221, 0.34);
        `}
      span {
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
      i {
        font-size: 18px;
        font-weight: 600;
        line-height: 21px;
        font-style: normal;
      }
    }
  }
`
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  margin-left: 0px;

  width: 100%;
  height: 75px;


  @media only screen and (min-width: 768px) {
    width: auto;
    margin-left: 24px;
  }
  ${BoxData} {
    margin-top: 0;
  }
`