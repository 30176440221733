import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Drawer, Layout, Menu } from 'antd'
import { DownOutlined,  UserOutlined,  LogoutOutlined,  UnlockOutlined } from '@ant-design/icons'
import PasswordEdit from '../../pages/account/PasswordEdit'
import { useLocalUser } from '../../hooks/useLocalUser'

const { Header: AntHeader } = Layout

export default function Header() {
  const [showEdit, setShowEdit] = useState(false)
  const [user] = useLocalUser()
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('user')
    navigate("/login")
  }

  const profile = (
    <ProfileName>
      <Users>
        <UserOutlined style={{ fontSize: 18 }} /> {user?.profile?.name} <DownOutlined />
      </Users>
      <Desc>{user?.profile?.companyName}</Desc>
    </ProfileName>
  )

  return (
    <AntHeader
      className="site-layout-background"
      style={{ padding: 0, backgroundColor: 'white' }}
    >
      <ProfileMenu>
        <Menu
          mode="horizontal"
          items={[
            {
              label: profile,
              key: 'profilemenu',
              children: [
                { label: 'Şifre değiştir', icon:<UnlockOutlined />, key: 'submenu-item-1', onClick: ()=> setShowEdit(!showEdit) },
                { label: 'Çıkış yap', icon:<LogoutOutlined />, key: 'submenu-item-2', onClick: ()=> logout() }
              ],
            },
        ]} />
      </ProfileMenu>
      <Drawer
        title="Parola güncelle"
        width="400"
        open={showEdit}
        onClose={() => setShowEdit(false)}
      >
        <PasswordEdit onSuccess={() => setShowEdit(false)} />
      </Drawer>
    </AntHeader>
  )
}

const ProfileMenu = styled.div`
  background-color: white;
  float: right;
  margin-right: 0px;
  color: black;
`
const ProfileName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  padding-right: 15px;

  width: 100%;
  height: 64px;
`
const Users = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 100%;
  line-height: 18px;

  .anticon.anticon-user {
    margin-right: 4px;
  }
  .anticon.anticon-down {
    margin-left: 4px;
  }
`
const Desc = styled.div`
  overflow: hidden;
  display: inline-block;

  padding-top: 5px;
  padding-right: 18px;

  width: 100%;
  max-width: 180px;

  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  text-align: right;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #666;
`
