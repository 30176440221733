import { Radio } from 'antd'

export default function ActiveDeletedFilter({ filter, onFilter, disableIsActive }) {
  const options = [
    { label: 'Hepsi', value: 0 },
    { label: 'Aktifler', value: 1, disabled: disableIsActive === true },
    { label: 'Pasifler', value: 2, disabled: disableIsActive === true },
    { label: 'Silinenler', value: 3 },
  ]

  function getValue() {
    if (filter?.isActive === undefined && filter?.isDeleted === false) return 0
    if (filter?.isActive !== undefined) return filter?.isActive ? 1 : 2
    if (filter?.isDeleted !== undefined) return 3
  }
  function change(e) {
    switch (e.target.value) {
      case 0:
        onFilter({ ...filter, isActive: undefined, isDeleted: false, pageIndex: 1, pageSize: 10 })
        break
      case 1:
        onFilter({ ...filter, isActive: true, isDeleted: false, pageIndex: 1, pageSize: 10 })
        break
      case 2:
        onFilter({ ...filter, isActive: false, isDeleted: false, pageIndex: 1, pageSize: 10 })
        break
      case 3:
        onFilter({ ...filter, isActive: undefined, isDeleted: true, pageIndex: 1, pageSize: 10 })
        break
      default:
        onFilter({ ...filter, isActive: undefined, isDeleted: undefined, pageIndex: 1, pageSize: 10 })
        break
    }
  }
  return (
    <Radio.Group
      options={options}
      onChange={change}
      value={getValue()}
      optionType="button"
      buttonStyle="solid"
      size="large"
    />
  )
}
