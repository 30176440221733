import React, { useEffect, useState } from 'react'
import { Button, Col, Drawer, message, Popconfirm, Row, Table, Input } from 'antd'
import { PlusOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons'
import Page from '../../components/layout/Page'
import userColumns from './user-columns'
import UserService from './UserService'
import UserEdit from './UserEdit'
import { MT20 } from '../../styles/global'
import { useCustomQueryStringOperations } from "../../hooks/useCustomQueryStringOperations";

const userService = new UserService()
const { Search } = Input

export default function Users() {
  const [ready, setReady] = useState(false)
  const [data, setData] = useState(null)
  const [filter, setFilter] = useState({
    orderBy: 'id_asc',
    pageIndex: 1,
    pageSize: 12,
    isDeleted: false,
  })
  const [, location, jsonifyQueryString, customHistoryReplace] = useCustomQueryStringOperations(filter, "", "users");
  const [current, setCurrent] = useState(null)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [visibleRegister, setVisibleRegister] = useState(false)
  const [searchText, setSearchText] = useState(null);
  const [suppliers, setSuppliers] = useState(null)


  useEffect(() => {
    if (jsonifyQueryString) {

      if (location.search !== "") {
        setSearchText(jsonifyQueryString.search);
        setReady(true)
        setFilter(jsonifyQueryString)
      } else {
        customHistoryReplace();
        setSelectedRowKeys([])
        load()
      }
    }
  }, [jsonifyQueryString])


  useEffect(() => {
    if (ready) {
      customHistoryReplace();
      setSelectedRowKeys([])
      load()
    }
  }, [filter])

  async function load(queries = filter) {
    setReady(false)
    userService.getAll(queries || filter)
      .then(m => setData(m.data)).catch(() => {
        message.error("Kullanıcılar yüklenemedi.")
      }).finally(() => {
        setReady(true)
      })
  }
  function add(row) {
    setCurrent(null)
    setVisibleRegister(true)
  }
  async function edit(row) {
    setReady(false)
    setCurrent(row)
    let detail = await userService.detail(row.id)
    setVisibleRegister(true)
    setCurrent(detail.data)
    setReady(true)
  }
  async function remove() {
    setReady(false)
    await userService.delete({ userIds: selectedRowKeys })
    message.success('Seçilen kullanıcılar silindi')
    setSelectedRowKeys([])
    load()
  }
  async function singleRemove(row) {
    setReady(false)
    await userService.delete({ userIds: [row.id] })
    message.success(`${row.firstName} ${row.lastName} silindi`)
    load()
  }
  function success(model) {
    setVisibleRegister(false)
    load()
    setSelectedRowKeys([])
  }

  function rowClicked(rowKey) {
    let newSelectedRowkeys = [...selectedRowKeys]
    if (newSelectedRowkeys.find((x) => x === rowKey) === undefined)
      newSelectedRowkeys.push(rowKey)
    else newSelectedRowkeys = newSelectedRowkeys.filter((x) => x !== rowKey)
    setSelectedRowKeys(newSelectedRowkeys)
  }

  return (
    <Page
      title="Kullanıcılar"
      subTitle="Sistemde yer alan kullanıcıları pasife alabilir, silebilir ya da yetki verebilirsiniz."
      routes={[
        {
          path: '/users',
          name: 'Kullanıcılar',
          breadcrumbName: 'Kullanıcılar',
          icon: <UserOutlined />,
        },
      ]}
      extra={[
        <Button key="add" type="primary" onClick={add}>
          <PlusOutlined /> Kullanıcı ekle
        </Button>,
        <Popconfirm
          key="delete"
          title={`Toplam ${selectedRowKeys.length} kullanıcı seçtiniz. İşleme devam etmek istediğinize emin misiniz?`}
          onConfirm={remove}
          okText="Evet"
          cancelText="Hayır"
        >
          {selectedRowKeys.length > 0 && <Button type="danger">
            <DeleteOutlined /> Sil
          </Button>}
        </Popconfirm>
      ]}
    >
      {/* Filter */}
      <Row gutter={16}>
        <Col>
          <Search className="ensearch" size="large" disabled={!ready} allowClear onChange={e => setSearchText(e.target.value ? e.target.value : null)} value={searchText} enterButton onSearch={val => setFilter({ ...filter, pageIndex: 1, search: val === "" ? null : val })} key="search" style={{ width: 200, marginRight: 10 }} placeholder="Ad veya soyad" />
        </Col>
      </Row>
      <MT20 />
      {/* Filter */}
      <Table
        bordered
        rowKey="id"
        scroll={{ x: 1100 }}
        dataSource={data?.result}
        columns={userColumns(edit, singleRemove)}
        rowSelection={{
          columnWidth: 40,
          selectedRowKeys,
          onChange: (vals) => setSelectedRowKeys(vals),
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              rowClicked(record.id)
            }, // click row
            onDoubleClick: (event) => {
              edit(record)
            },
          }
        }}
        loading={!ready}
        pagination={{
          pageSize: filter.pageSize,
          total: data?.rowCount,
          showTotal: (total, range) => `Toplam ${total} kullanıcı var`,
          current: filter.pageIndex,
          position: ['bottomCenter'],
        }}
        onChange={(f, _, s) => s.order !== undefined 
          ? setFilter((p) => ({ ...p, pageIndex: f.current, orderBy: `${s.columnKey}_${s.order}` })) 
          : setFilter((p) => ({ ...p, pageIndex: f.current, orderBy: `${'id_asc'}` })
        )}
      />
      <Drawer
        open={visibleRegister}
        onClose={() => { setVisibleRegister(false); setSelectedRowKeys([]) }}
        width={400}
        bodyStyle={{ paddingTop: 20 }}
        title={current ? 'Kullanıcı düzenle' : 'Kullanıcı ekle'}
      >
        <UserEdit onSuccess={success} model={current} suppliers={suppliers} />
      </Drawer>
    </Page>
  )
}
