import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Card, Form, Row, Col, Input, Button, message } from 'antd'
import LoginContainer from './LoginContainer'
import UserService from './UserService'

const userService = new UserService()

export default function ForgotPassword() {
  const [ready, setReady] = useState(true)
  const [formRef] = Form.useForm()
  const navigate = useNavigate()

  const submit = (model) => {
    setReady(false)
    userService
      .forgotPassword(model.mailAddress)
      .then((x) => {
        message.success(
          'Şifremi unuttum isteğinizi aldık. Eğer bu mail adresi sahibi iseniz size talimatları içeren bir mail göndereceğiz.',
          5
        )
        navigate({ pathname: '/login', state: { mailAddress: model.mailAddress } })
      })
      .catch((x) => {
        message.error(
          'Gönderim sırasında bazı sorunlar yaşadık. Lütfen daha sonra tekrar deneyin.'
        )
      })
      .finally((x) => setReady(true))
  }

  return (
    <LoginContainer>
      <Card>
        <Form
          form={formRef}
          layout="vertical"
          onFinish={submit}
          size="large"
          hideRequiredMark
        >
          <Row gutter={[16, 0]}>
            <Col span={24}>
              <Form.Item
                label="E-posta adresi"
                name="mailAddress"
                rules={[
                  { required: true, message: 'Lütfen e-posta adresinizi girin' },
                  { type: 'email', message: 'Geçersiz e-posta adresi' },
                ]}
                hasFeedback
              >
                <Input type="email" placeholder="julide.deniz@shipfingo.com" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: '100%', marginTop: 20 }}
                onClick={() => formRef.submit()}
                disabled={!ready}
                loading={!ready}
              >
                Gönder
              </Button>
            </Col>
            <Col span={24} style={{ textAlign: 'center', marginTop: 10 }}>
              <Link to="/login">Giriş yap</Link>
            </Col>
          </Row>
        </Form>
      </Card>
    </LoginContainer>
  )
}
