export const Default = {
  title: 'Varsayılan Şablon',
  description: 'Varsayılan Şablon',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="default">
      <section class="template-content editable" data-template="default">
        <p>Varsılan içerik şablonu.</p>
      </section>
    </div>
    <p></p>`,
}

export const FormulaWidget = {
  title: 'Formül Şablonu',
  description: 'Formül Şablonu',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="formula">
      <section class="template-content editable" data-template="formula">{#Formül#}</section>
    </div>
    <p></p>`,
}

export const Heading = {
  title: 'Başlık Şablonu',
  description: 'İçerik başlığını açıklayan metin alanı.',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="heading">
      <section class="template-content editable" data-template="heading">
          <p>Başlık şablonu.</p>
      </section>
    </div>
    <p></p>`,
}

export const MostPreferred = {
  title: 'En Çok Tercih Edilen Şablonu',
  description: 'En çok tercih edilen ürünlerin gösterildiği alan.',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="mostPreferred">
      <section class="template-content editable" data-template="mostPreferred">
      <p></p>
      </section>
    </div>`,
}

export const MostPreferredCreditCard = {
  title: 'En Çok Tercih Edilen Kredi Kartı Şablonu',
  description: 'En çok tercih edilen kredit kartlarının gösterildiği alan.',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="mostPreferredCreditCard">
      <section class="template-content editable" data-template="mostPreferredCreditCard">
      <p></p>
      </section>
    </div>`,
}

export const StickyMain = {
  title: 'Genel Sticky Şablonu',
  description: 'İçerik içerisinde tedarikçi karşılaştırma alanına yönlendiren sabit bar şablonu.',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="sticky-main">
      <section class="template-content widget__sticky nonEditable" data-template="sticky" data-spec="main" data-widget-sticky>
        <p class="editable">Karşılaştırma yapmak istediğiniz sektörü seçin!</p>
      </section>
    </div>
  <p></p>`,
}

export const StickyEnergy = {
  title: 'Enerji Sticky Şablonu',
  description: 'İçerik içerisinde tedarikçi karşılaştırma alanına yönlendiren sabit bar şablonu.',
  content: `<p></p>
    
  <p></p>`,
}

export const StickyFinance = {
  title: 'Kredi Sticky Şablonu',
  description: 'İçerik içerisinde kredi karşılaştırma alanına yönlendiren sabit bar şablonu.',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="sticky-finance">
      <section class="template-content widget__sticky nonEditable" data-template="sticky" data-spec="finance" data-widget-sticky>
        
      </section>
    </div>
  <p></p>`,
}

export const StickyInsurance = {
  title: 'Sigorta Sticky Şablonu',
  description: 'İçerik içerisinde sigorta karşılaştırma alanına yönlendiren sabit bar şablonu.',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="sticky-insurance">
      <section class="template-content widget__sticky nonEditable" data-template="sticky" data-spec="insurance"  data-widget-sticky>
        
      </section>
    </div>
  <p></p>`,
}

export const CalculateKwh = {
  title: 'Elektrik Hesaplama Şablonu',
  description: 'Girilen fatura miktarına göre elektrik faturasını hesaplayan şablon.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="kwh-calculate-bill-usage">
    <section class="template-content nonEditable" data-template="kwh-calculate-bill-usage"><p></p></section>
  </div>
    <p></p>`,
}

export const CTAEnergy = {
  title: 'CTA Enerji Şablonu',
  description: 'Tedarikçi değiştirmek için abone grubu seçtirme şablonu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="cta-energy">
    <section class="template-content" data-template="cta-energy">
        <div class="widget__cta__content">
            
        </div>
    </section>
  </div>
    <p></p>`,
}

export const CTAFinance = {
  title: 'CTA Kredi Şablonu',
  description: 'Kredi ihtiyaç türünü seçtirme şablonu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="cta-finance">
    <section class="template-content" data-template="cta-finance">
        <div class="widget__cta__content">
            
        </div>
    </section>
  </div>
    <p></p>`,
}

export const CTAInsurance = {
  title: 'CTA Sigorta Şablonu',
  description: 'Sigorta türünü seçtirme şablonu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="cta-insurance">
    <section class="template-content" data-template="cta-insurance">
        <div class="widget__cta__content">
            
        </div>
    </section>
  </div>
    <p></p>`,
}

export const Highlighting = {
  title: 'Vurgulayıcı Başlık Şablonu',
  description: 'Özel tasarımsal metin şablonu',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="highlighting">
    <section class="template-content" data-template="highlighting">
        <p class="editable" disable-toc>Vurgulayıcı Başlık</p>
        <p class="editable">Vurgulayıcı başlık detayı</p>
    </section>
  </div>
    <p></p>`,
}

export const Accordion = {
  title: 'Akordiyon Alan Şablonu',
  description: 'Altına lütfen sadece akordiyon nesnesi ekleyiniz.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="accordion">
    <section class="template-content editable" data-template="accordion">
    <div class= "widget__accordion__item" data-template="accordion-item">
        <h3 class="widget__accordion__title editable" disable-poc data-accordion>Başlık</h3>
        <div class="widget__accordion__content editable">
            <p class="editable">Detay</p>
        </div>
    </div >
    <p></p>
    </section>
  </div>
    <p></p>`,
}

export const AccordionItem = {
  title: 'Akordiyon Nesnesi',
  description: 'Akordiyon alan şablonu içerisinde kullanılmalıdır..',
  content: `
  <div class= "widget__accordion__item" data-template="accordion-item">
    <h3 class="widget__accordion__title editable" disable-poc data-accordion>Başlık</h3>
    <div class="widget__accordion__content editable">
        <p class="editable">Detay</p>
    </div>
  </div>
    <p></p>`,
}

export const List = {
  title: 'İkonlu Listeleme Şablonu',
  description: 'İçerik başlığını açıklayan metin alanı.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="list">
    <ul class="template-content editable" data-template="list">
        <li class="list-style -tick">Metin giriniz..</li>
        <li class="list-style -tick">Metin giriniz..</li>
        <li class="list-style -star">Metin giriniz..</li>
        <li class="list-style -star">Metin giriniz..</li>
        <li class="list-style -attention">Metin giriniz..</li>
        <li class="list-style -attention">Metin giriniz..</li>
        <li class="list-style -question">Metin giriniz..</li>
        <li class="list-style -question">Metin giriniz..</li>
        <li class="list-style -active">Metin giriniz..</li>
        <li class="list-style -active">Metin giriniz..</li>
        <li class="list-style -inactive">Metin giriniz..</li>
        <li class="list-style -inactive">Metin giriniz..</li>
    </ul>
  </div>
    <p></p>`,
}

export const TabPanel = {
  title: 'Tab Şablonu',
  description: 'Lütfen tab başlığı kadar detayını eklemeyi unutmayınız.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="tab-panel">
    <section class="template-content" data-template="tab-panel">
        <div class="tab-content__container">
            <h2 class="editable">Başlık Açıklama</h2>
            <div class="tab-content__box">
                <div class="template-content-inside editable" data-template="tab-title-area">

                </div>
                <div class="template-content-inside editable" data-template="tab-detail-area">
                    
                </div>
            </div>
        </div>
    </section>
  </div>
    <p></p>`,
}

export const TabPanelTitle = {
  title: '--- Başlık',
  description: 'Tab Şablon Başlığı',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="tab-title">
    <div class="template-content editable" data-template="tab-title">Başlık</div>
  </div>
    <p></p>`,
}

export const TabPanelDetail = {
  title: '--- Detay',
  description: 'Tab Şablon Detayı',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="tab-detail">
    <div class="template-content editable" data-template="tab-detail">
        <h3>Başlık</h3>
        <p>Detay</p>
    </div>
  </div>
    <p></p>`,
}

export const SubPages = {
  title: 'Alt Sayfa Şablonu',
  description: 'Sayfanın alt kırılımlarını gösteren şablon.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="subpages">
    <section class="template-content nonEditable" data-template="subpages"><p></p></section>
  </div>
    <p></p>`,
}

export const ComparisonCorporate = {
  title: 'İş Yeri Karşılaştırma Şablonu',
  description: 'İş yeri tarifeleri karşılaştırma tablosu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="comparison-corporate">
    <section class="template-content nonEditable" data-template="comparison-corporate"><p></p></section>
  </div>
    <p></p>`,
}

export const ComparisonIndustry = {
  title: 'Sanayi Karşılaştırma Şablonu',
  description: 'Sanayi tarifeleri karşılaştırma tablosu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="comparison-industry">
    <section class="template-content nonEditable" data-template="comparison-industry"><p></p></section>
  </div>
    <p></p>`,
}

export const LineChart = {
  title: 'LineChart Şablonu',
  description: 'Line chart',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="line-chart">
    <section class="template-content editable" data-template="line-chart">{#TestChart#}</section>
  </div>
    <p></p>`,
}

export const BarChart = {
  title: 'BarChart Şablonu',
  description: 'Bar chart',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="bar-chart">
    <section class="template-content editable" data-template="bar-chart">{#TestChart#}</section>
  </div>
    <p></p>`,
}

export const PieChart = {
  title: 'PieChart Şablonu',
  description: 'Pie chart',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="pie-chart">
    <section class="template-content editable" data-template="pie-chart">{#TestChart#}</section>
  </div>
    <p></p>`,
}

export const Campaign = {
  title: 'Kampanya Şablonu',
  description: 'Kampanya ekleme şablonu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="campaign">
    <section class="template-content editable" data-template="campaign">{#KampanyaKodu#}</section>
  </div>
    <p></p>`,
}

export const CalculateCreditByBank = {
  title: 'Bankaya Göre Kredi Hesaplama Şablonu',
  description: 'Bankaya Göre Kredi Hesaplama şablonu.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="credit-calculate-by-bank">
    <section class="template-content editable" data-template="credit-calculate-by-bank">{#BankaId#}</section>
  </div>
    <p></p>`,
}

export const CalculateDeposit = {
  title: 'Mevduat Hesaplama Şablonu',
  description: 'Girilen tutar ve vade uzunluğuna göre vade sonu toplam tutarı hesaplayan şablon.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="deposit-calculate">
    <section class="template-content nonEditable" data-template="deposit-calculate"><p></p></section>
  </div>
    <p></p>`,
}

export const CalculateCarTax = {
  title: 'Araç ÖTV-KDV Hesaplama Şablonu',
  description: 'Araçların vergisini hesaplayan şablon.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="car-tax-calculate">
    <section class="template-content nonEditable" data-template="car-tax-calculate"><p></p></section>
  </div>
    <p></p>`,
}

export const CalculateLoanPaymentPlan = {
  title: 'Kredi Ödeme Planı Oluşturma Aracı Şablonu',
  description: 'Kredi türü, girilen kredi tutarı, vade ve faiz oranına göre ödeme planı oluşturan şablon.',
  content: `<p></p>
  <div class="nonEditable template-refactor" data-title="create-loan-payment-plan">
    <section class="template-content nonEditable" data-template="create-loan-payment-plan"><p></p></section>
  </div>
    <p></p>`,
}

export const PersonalDailyCreditInterestTable = {
  title: 'İhtiyaç Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu',
  description: 'İhtiyaç Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="personalDailyCreditInterest">
      <section class="template-content nonEditable" data-template="personalDailyCreditInterest" data-spec="İhtiyaç Kredisi">
        <p>İhtiyaç Kredisi Günlük Bankalara Göre Faiz Tablosu şablonu.</p>
      </section>
    </div>
    <p></p>`,
}

export const ResidenceDailyCreditInterestTable = {
  title: 'Konut Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu',
  description: 'Konut Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="residenceDailyCreditInterest">
      <section class="template-content nonEditable" data-template="residenceDailyCreditInterest" data-spec="Konut Kredisi">
        <p>Konut Kredisi Günlük Bankalara Göre Faiz Tablosu şablonu.</p>
      </section>
    </div>
    <p></p>`,
}

export const VehicleDailyCreditInterestTable = {
  title: 'Taşıt Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu',
  description: 'Taşıt Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu',
  content: `<p></p>
    <div class="nonEditable template-refactor" data-title="vehicleDailyCreditInterest">
      <section class="template-content nonEditable" data-template="vehicleDailyCreditInterest" data-spec="Taşıt Kredisi">
        <p>Taşıt Kredisi Günlük Bankalara Göre Faiz Tablosu şablonu.</p>
      </section>
    </div>
    <p></p>`,
}
