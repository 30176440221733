import { Button, Tooltip } from 'antd'
import { ZoomOutOutlined, ZoomInOutlined, FullscreenExitOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export default function ScreenZoom() {
  let zoom = 100
  const ZoomOut = (x) => {
    zoom -= x
    document.body.style.zoom = zoom + '%'
  }
  const ZoomIn = (x) => {
    zoom += x
    document.body.style.zoom = zoom + '%'
  }
  const ZoomReset = (x) => {
    zoom = x
    document.body.style.zoom = zoom + '%'
  }
  return (
    <Container>
      <Row>
        <Tooltip placement="left" title="Sayfayı Uzaklaştır">
          <Button onClick={() => ZoomOut(10)} type="text">
            <ZoomOutOutlined />
          </Button>
        </Tooltip>
        <Tooltip placement="bottom" title="Sayfayı Resetle">
        <Button onClick={() => ZoomReset(100)} type="text">
          <FullscreenExitOutlined />
        </Button>
        </Tooltip>
        <Tooltip placement="top" title="Sayfayı Yakınlaştır">
        <Button onClick={() => ZoomIn(10)} type="text">
          <ZoomInOutlined />
        </Button>
        </Tooltip>
      </Row>
      {/* <p>Sayfa Zoom</p> */}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  top: -1px;
  right: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  background-color: white;
  border-radius: 4px;

  button {
    margin-left: 4px;
    margin-bottom: 0;

    &:first-child {
      margin-left: 0;
    }
  }
`
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
