import { useEffect, useState } from 'react'
import { Link, useLocation, Outlet } from 'react-router-dom';
import styled from 'styled-components'
import { Layout, Menu, Button, notification, ConfigProvider } from 'antd'
import { SwapOutlined, UserOutlined, MailOutlined, PicLeftOutlined, ThunderboltFilled, LayoutOutlined, CloudUploadOutlined, TagsOutlined, SlidersOutlined, SnippetsOutlined } from '@ant-design/icons'
import turkish from 'antd/locale/tr_TR'
import Header from './Header'
import Footer from './Footer'
import colors from '../../styles/colors'
import { useLocalUser } from '../../hooks/useLocalUser'
import { getAssetsUrl } from '../../configs/origins'
import { createSocketConnection } from '../../configs/web-socket'

const { Content, Sider } = Layout
const CloseIcon = <svg
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M18.4881 6.12981C19.1706 6.81234 19.1706 7.91894 18.4881 8.60147L15.0903 11.9993L18.4881 15.3985C19.1706 16.0811 19.1706 17.1877 18.4881 17.8702L17.8702 18.4881C17.1877 19.1706 16.0811 19.1706 15.3985 18.4881L12 15.0896L8.60147 18.4881C7.91894 19.1706 6.81234 19.1706 6.12981 18.4881L5.5119 17.8702C4.82937 17.1877 4.82937 16.0811 5.5119 15.3985L8.91043 12L5.5119 8.60147C4.82937 7.91894 4.82937 6.81234 5.5119 6.12981L6.12981 5.5119C6.81234 4.82937 7.91894 4.82937 8.60147 5.5119L12 8.91043L15.3985 5.5119C16.0811 4.82937 17.1877 4.82937 17.8702 5.5119L18.4881 6.12981Z"
    fill="#29304B"
  />
</svg>

export default function BaseLayout() {
  const [audio] = useState(new Audio('/assets/sound/dindin.mp3'));
  const location = useLocation();
  const [user] = useLocalUser('user')
  const [collapsed, setCollapsed] = useState(false)
  const [selectedKey, setSelectedKey] = useState('1')

  const getInitialOpenKeys = (user) => {
    if (user?.isSupplier) {
      return ["portal"]
    } else if (user?.isAuthor) {
      return ["contents"]
    } else {
      return []
    }
  }
  const [openKeys, setOpenKeys] = useState(getInitialOpenKeys(user))

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.menu) {
      setOpenKeys([params.menu]);
    }
    if (params.subMenu) {
      setSelectedKey(params.subMenu);
    }
  }, [location])

  useEffect(() => {
    const connection = createSocketConnection(user?.access_token)
    connection?.on("CorporateApplicationCreated", data => {
      const key = `open${Date.now()}`
      const btn = <a href={data.link} type="primary"><Button type="primary">Görüntüle</Button></a>
      const closeIcon = CloseIcon
      notificationOpen();
      notification.open({
        message: "Yeni talep geldi",
        description: `${data.reference} referans numarası ile sisteme kaydedilmiş`,
        duration: 0,
        top: 75,
        btn,
        closeIcon,
        key
      })
    });
    connection?.on("DefaultApplicationCreated", data => {
      const key = `open${Date.now()}`
      const btn = <a href={data.link} type="primary"><Button type="primary">Görüntüle</Button></a>
      const closeIcon = CloseIcon
      notificationOpen();
      notification.open({
        message: "Yeni başvuru geldi",
        description: `${data.reference} referans numarası ile sisteme kaydedilmiş, ${data.supplierName} isimli tedarikçi seçilmiştir`,
        duration: 0,
        top: 75,
        btn,
        closeIcon,
        key
      })
    });
    connection?.start().then(() => console.log("connection started"))
  }, [])

  const notificationOpen = () => {
    audio.loop = true
    audio.volume = 0.1;
    audio.currentTime = 0
    audio.play()
    const timeout = setTimeout(() => {
      audio.pause();
      audio.currentTime = 0
    }, 1000);
    return () => clearTimeout(timeout);
  }

  function onOpenChange(keys) {
    let rootSubmenuKeys = menuItems.map(m => m.key)
    let latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  }



  const templatesMenu = [
    {
      label: 'Şablonlar', icon: <LayoutOutlined />, key: 'templates', children: [
        { label: <Link to="/templates/sms">SMS</Link>, key: 'templates.1' },
        { label: <Link to="/templates/email">Email</Link>, key: 'templates.2' }
      ]
    }
  ]



  const storageMenu = [
    { label: <Link to="/storages">Depolama</Link>, icon: <CloudUploadOutlined />, key: 'storage' }
  ]

  const scheduledSmsMenu = [
    { label: <Link to="/settings/ScheduledSms">Toplu SMS Gönderimi</Link>, icon: <MailOutlined />, key: 'ScheduledSms' }
  ]

  const usersMenu = [
    { label: <Link to="/users">Kullanıcılar</Link>, icon: <UserOutlined />, key: 'users' }
  ]

  const menuItems = [
    ...(user?.permissions?.some((x) => x === 'templates') ? templatesMenu : []),
    ...(user?.permissions?.some((x) => x === 'storage') ? storageMenu : []),
    ...(user?.permissions?.some((x) => x === 'settings') ? scheduledSmsMenu : []),
    ...(user?.permissions?.some((x) => x === 'users') ? usersMenu : []),
  ]

  return (
    <ConfigProvider locale={turkish}>
      <Layout style={{ minHeight: '100vh' }}>
        <Aside collapsible collapsed={collapsed} onCollapse={setCollapsed}>
          <LogoContainer>
            <Link to={`${user?.isAuthor ? '/pages' : (user?.isSupplier ? '/portal/reports' : '/')}`}>
              <img
                src={`/assets/image/logo${collapsed ? "-i" : ""}.png`}
                alt="Logo"
                height={30}
              />
            </Link>
          </LogoContainer>
          <Menu
            items={menuItems}
            theme="dark"
            selectedKeys={[selectedKey]}
            openKeys={openKeys}
            mode="inline"
            onClick={({ key }) => setSelectedKey(key)}
            onOpenChange={onOpenChange}
          />
        </Aside>
        <Layout>
          <Header />
          <Content>
            <Outlet />
          </Content>
          <Footer />
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export const Aside = styled(Sider)`
  background-color: ${colors.sapphire};

  .ant-layout-sider-trigger {
    background-color: ${colors.sapphireLight};
  }
  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: ${colors.sapphire};
  }
`
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding:2px;

  height: 64px;

  background-color: ${colors.white};
  border-right: 1px solid ${colors.borderGost};

  cursor: pointer;
  transition: background-color 300ms ease-in-out;

  :hover {
    background-color: ${colors.borderGost};
  }
`
