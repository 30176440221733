/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Col, Form, Input, Button, Row, Card, message, Tabs, Statistic } from 'antd'
import { MailOutlined, MobileOutlined } from '@ant-design/icons'
import jwtDecode from 'jwt-decode'
import UserService from './UserService'
import LoginContainer from './LoginContainer'
import PhoneNumber from '../../components/PhoneNumber'
import { useLocalUser } from '../../hooks/useLocalUser'

const userService = new UserService()
const { Countdown } = Statistic;

export default function Login({ location }) {
  const [ready, setReady] = useState(true)
  const [visibleCode, setVisibleCode] = useState(false)
  const [form] = useState(location?.state)
  const [, setUser] = useLocalUser()
  const [formRef] = Form.useForm()
  const [smsFormRef] = Form.useForm()

  const navigate = useNavigate()

  async function success(model) {
    let jwt = jwtDecode(model.token)
    message.success(`Merhaba ${jwt.firstName}, oturumunu düzenliyoruz.`)
    setUser({ access_token: model.token })
    await userService.me()
    await new Promise(resolve => setTimeout(resolve, 1000))
    if (jwt?.role === "author" || (Array.isArray(jwt.role) && jwt?.role?.lenght === 0)) {
      navigate('/pages')
    }
    else if (jwt?.role === "supplier" || (Array.isArray(jwt.role) && jwt?.role?.lenght === 0)) {
      navigate('/portal/reports')
    }
    else
      navigate('/')
  }

  const login = (model) => {
    setReady(false)
    userService
      .login(model)
      .then((x) => success(x.data))
      .catch((x) => {
        message.error("Geçersiz kullanıcı bilgileri veya pasif kullanıcı")
      })
      .finally((x) => setReady(true))
  }

  const otpLogin = (model) => {
    setReady(false)
    if (!visibleCode) {
      userService
        .otp(model)
        .then((x) => {
          message.success('Cep telefonunuza bir giriş kodu gönderildi')
          setVisibleCode(true)
        })
        .catch((x) => {
          message.error('Cep telefonu numaranızın doğru olduğundan emin olun.')
        })
        .finally((x) => setReady(true))
      return
    }
    userService
      .otpLogin(model)
      .then((x) => success(x.data))
      .catch((x) => {
        message.error('Cep telefonunuza gelen şifreyi doğru girdiğinizden emin olun.')
      })
      .finally((x) => setReady(true))
  }

  const emailLogin = <Form
    form={formRef}
    layout="vertical"
    size="large"
    onFinish={login}
    initialValues={form}
    hideRequiredMark
  >
    <Row gutter={[8, 0]}>
      <Col span={24}>
        <Form.Item
          label="E-posta adresi"
          name="email"
          rules={[
            { required: true, message: 'Lütfen e-posta adresinizi girin' },
            { type: 'email', message: 'Geçersiz e-posta adresi' },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          label="Parola"
          name="password"
          rules={[
            { required: true, message: 'Lütfen şifrenizi girin' },
            { min: 6, message: 'Şifre en az 6 karakterli olmalıdır' },
          ]}
        >
          <Input.Password />
        </Form.Item>
      </Col>
      <Col span={24} style={{ paddingTop: 10 }}>
        <Link style={{ float: 'right' }} to="/forgot-password">
          Şifremi unuttum
        </Link>
      </Col>
      <Col span={24}>
        <Button
          htmlType="submit"
          type="primary"
          style={{ width: '100%', marginTop: 20 }}
          onClick={() => formRef.submit()}
          disabled={!ready}
          loading={!ready}
        >
          Giriş yap
        </Button>
      </Col>
    </Row>
  </Form>

  const smsLogin = <Form
    form={smsFormRef}
    layout="vertical"
    size="large"
    onFinish={otpLogin}
    hideRequiredMark
  >
    <Row gutter={[8, 0]}>
      <Col span={24} style={{ display: visibleCode ? 'none' : 'block' }}>
        <PhoneNumber name="Phone" autoComplete="off" />
      </Col>
      {visibleCode && (
        <>
          <Col span={24}>
            <Countdown
              value={Date.now() + 180 * 1000}
              onFinish={() => setVisibleCode(false)}
              format="mm:ss"
            />
          </Col>
          <Col span={24}>
            <Form.Item
              label="Şifre"
              name="Code"
              rules={[
                {
                  required: true,
                  message: 'Lütfen SMS ile gelen şifrenizi girin',
                },
                { min: 6, message: 'Şifre en az 6 karakterli olmalıdır' },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </>
      )}
      <Col span={24}>
        <Button
          type="primary"
          style={{ width: '100%', marginTop: 20 }}
          onClick={() => smsFormRef.submit()}
          disabled={!ready}
          loading={!ready}
        >
          {visibleCode ? 'Giriş' : 'Kod Gönder'}
        </Button>
      </Col>
    </Row>
  </Form>

  const tabItems = [
    { label: <span><MailOutlined />E-Posta ile giriş</span>, key: 'child1', children: emailLogin },
    { label: <span><MobileOutlined />SMS ile giriş</span>, key: 'child2', children: smsLogin }
  ]

  return (
    <LoginContainer>
      <Card style={{ marginBottom: 10, width: '100%', maxWidth: 400 }}>
        <Tabs defaultActiveKey="1" centered items={tabItems} />
      </Card>
    </LoginContainer>
  )
}
