import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';

export default function InputMask(props) {
    return <EnMaskedInput {...props}></EnMaskedInput>;
  }

  export const EnMaskedInput = styled(NumericFormat)`
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  height: 40.15px;
  padding: 6.5px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
`;