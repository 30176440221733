import { Form } from 'antd'
import InputMask from './InputMask'

export default function PhoneNumber({ name, ...rest }) {
  return (
    <Form.Item
      name={name ? name : 'phone'}
      label="Telefon numarası"
      rules={[
        { required: true, message: 'Lütfen telefon numarası giriniz.' },
        {
          validator: (_, value) => {
            value = String(value)
            value = value.replace(/[^+\d]+/g, '')
            if (value[0] === undefined) return Promise.reject()
            if (value[0] !== '5' && value.length !== 10)
              return Promise.reject(new Error('Telefon numarasını eksik girdiniz'))
            if (value[0] === '5' && value[1] >= '7')
              return Promise.reject(
                new Error('Cep telefonu numarasının doğru olduğundan emin olun.')
              )
            if (value[0] === '5' && value.length !== 10)
              return Promise.reject(new Error('Cep telefonu numarasını eksik girdiniz.'))
            return Promise.resolve()
          },
        },
      ]}
    >
      <InputMask
        mask='_'
        format="##########"
        placeholder="Lütfen telefon numarasını yazınız"
        size="large"
        inputMode="tel"
        {...rest}
      />
    </Form.Item>
  )
}