import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { useLocalUser } from '../hooks/useLocalUser'

export default function PrivateRoute({ component: Component, ...props }) {
  const [user] = useLocalUser()

  if (user?.isAuthenticated) {
    if (user?.isSupplier && window.location.pathname === '/')
      return <Navigate to='/portal/reports' replace={true} />
    else if (user?.isAuthor && window.location.pathname === '/')
      return <Navigate to='/pages' replace={true} />
    else
      return <Outlet {...props} />
  }
  return <Navigate to='/login' replace={true} />
}
