import React, { useEffect, useState } from "react";
import { Tree, Input } from "antd";
import styled from "styled-components";

const { DirectoryTree } = Tree;
const { Search } = Input;

export default function StorageDirectories({ directoryData, onSelect, ready }) {
  const [treeData, setTreeData] = useState(null);
  const [treeDataList, setTreeDataList] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(null);
  const [autoExpandParent, setAutoExpandParent] = useState(false);

  useEffect(() => {
    GetTreeData();
  }, [directoryData]);

  function GetTreeData() {
    setTreeData(directoryData);
    generateSearchList(directoryData);
    setTreeDataList(treeDataList);
  }

  const onExpand = eKeys => {
    setExpandedKeys(eKeys);
    setAutoExpandParent(false);
  }

  const generateSearchList = data => {
    for (let i = 0; i < data.length; i++) {
      const node = data[i];
      treeDataList.push({ key: node.key, title: node.title });
      if (node.children) {
        generateSearchList(node.children);
      }
    }
  };

  const getParentKey = (title, tree) => {
    let parentTitle;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some(item => item.key === title)) {
          parentTitle = node.key;
        } else if (getParentKey(title, node.children)) {
          parentTitle = getParentKey(title, node.children);
        }
      }
    }
    return parentTitle;
  };

  const onSearchTree = e => {
    let { value } = e.target;
    let expandedKeys = treeDataList
      .map(item => {
        if (item.title.toLocaleLowerCase("tr").indexOf(value.toLocaleLowerCase("tr")) > -1) {
          return getParentKey(item.key, treeData);
        }
        return null;
      })
      .filter((item, i, self) => item && self.indexOf(item) === i);

    if (value === "") {
      expandedKeys = ["/"];
    }

    setAutoExpandParent(true);
    setExpandedKeys(expandedKeys);
  };

  return (
    <>
      <Search className="ensearch" size="large" style={{ marginBottom: 8 }} placeholder="Ara" onChange={onSearchTree} disabled={!ready} />
      <StyledDirectoryTree
        defaultExpandAll
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        expandAction="doubleClick"
        treeData={treeData}
        onExpand={onExpand}
        onSelect={onSelect}
        disabled={!ready}
      />
    </>
  );
}

const StyledDirectoryTree = styled(DirectoryTree)`
background-color: transparent;
.ant-tree-list{
  border: 1px solid #1890ff;

  .ant-tree-treenode:hover {
    color: #fff !important;
    &::before {
      background: #40a9ff !important;
    }
  }

  .ant-tree-treenode-selected:hover::before,
  .ant-tree-treenode-selected::before {
    background: #1890ff !important;
  }

  .ant-tree-treenode::before {
    bottom: 0px !important;
  }
}

`;
