import React, { useEffect, useState } from 'react'
import { DatePicker, Drawer, message } from 'antd'
import dayjs from 'dayjs'
import locale from 'antd/locale/tr_TR'
import DashBoardService from './DashBoardService'
import { Container, Header, FilterRange, Button, Content, ContentHeader, Title, Desc, Item, Row, Box, DetailButton, BoxTitle, BoxData, BoxList, } from './DashBoardStyle'

const { RangePicker } = DatePicker
const service = new DashBoardService()
const dateFormat = 'DD.MM.YYYY'
const dateFormat2 = 'YYYY-MM-DD'

export default function Dashboard() {
  const [dataSupplier, setDataSupplier] = useState([])
  const [dataCallStatus, setDataCallStatus] = useState([])
  const [dataStage, setDataStage] = useState([])
  const [dataPage, setDataPage] = useState({})
  const [dataAnalytics, setDataAnalytics] = useState([])
  const [drawerVisibility, setDrawerVisibility] = useState(false)
  const [currentItem, setCurrentItem] = useState(5)
  const [buttonDate, setButtonDate] = useState([dayjs(), dayjs()])

  useEffect(() => {
    load([dayjs().format(dateFormat2), dayjs().format(dateFormat2)])
    service.getPageInfo()
      .then(x => setDataPage(x.data))
      .catch(() => { message.error("Veri yüklenemedi") })
  }, [])

  function load(datefilter) {
    service.getSupplierInfo({ startdate: datefilter[0], endDate: datefilter[1] + " 23:59:59" })
      .then(x => setDataSupplier(x.data))
      .catch(() => { message.error("Veri yüklenemedi") })
    service.getCallStatusInfo({ startdate: datefilter[0], endDate: datefilter[1] + " 23:59:59" })
      .then(x => setDataCallStatus(x.data))
      .catch(() => { message.error("Veri yüklenemedi") })
    service.getStageInfo({ startdate: datefilter[0], endDate: datefilter[1] + " 23:59:59" })
      .then(x => setDataStage(x.data))
      .catch(() => { message.error("Veri yüklenemedi") })
    service.getAnalyticsInfo({ startdate: datefilter[0], endDate: datefilter[1] + " 23:59:59" })
      .then(x => setDataAnalytics(x.data))
      .catch(() => { message.error("Veri yüklenemedi") })
  }

  function dataRangeChange(date, dateString) {
    setButtonDate(date)
    if (dateString[0] !== "" && dateString[1] !== "")
      load([dayjs(dateString[0], dateFormat).format(dateFormat2), dayjs(dateString[1], dateFormat).format(dateFormat2)])
  }

  const filterRangeClicked = (index) => {
    let days = ({
      1: 60,
      2: 30,
      3: 15,
      4: 7
    })[index] ?? 1;
    setCurrentItem(index === currentItem ? -1 : index)
    setButtonDate([dayjs().subtract(days, 'days'), dayjs()])
    load([dayjs().subtract(days, 'days').format(dateFormat2), dayjs().format(dateFormat2)])
  }

  const showDrawer = () => {
    setDrawerVisibility(true)
  }

  return (
    <Container>
      <Header>
        <RangePicker
          locale={locale}
          suffixIcon={true}
          allowClear
          format={dateFormat}
          value={buttonDate}
          disabledDate={(current) => current && current > dayjs().endOf('day')}
          onChange={dataRangeChange}
          presets={{
            Bugün: [dayjs(), dayjs()],
            'Son 7 gün': [dayjs().subtract(7, 'days'), dayjs()],
            'Son 30 gün': [dayjs().subtract(30, 'days'), dayjs()],
          }}
        />
        <FilterRange>
          <Button active={currentItem === 1} onClick={() => filterRangeClicked(1)}>Son 60 Gün</Button>
          <Button active={currentItem === 2} onClick={() => filterRangeClicked(2)}>Son 30 Gün</Button>
          <Button active={currentItem === 3} onClick={() => filterRangeClicked(3)}>Son 15 Gün</Button>
          <Button active={currentItem === 4} onClick={() => filterRangeClicked(4)}>Son 7 Gün</Button>
          <Button active={currentItem === 5} onClick={() => filterRangeClicked(5)}>Bugün</Button>
        </FilterRange>
      </Header>
      <Content>
        <ContentHeader>
          <Title>Dashboard</Title>
          <Desc>CK ile ilgili genel istatistikler</Desc>
        </ContentHeader>
        <Item>
          <Box>
            {/* <DetailButton /> */}
            <BoxTitle>shipfingo.com</BoxTitle>
            {dataAnalytics && <BoxData>
              <h2>{dataAnalytics.map(m => m.value).reduce((a, b) => a + b, 0)}</h2>
              <p>etkin kullanıcı sayısı</p>
            </BoxData>}
            {dataAnalytics && <BoxList>
              <h3>Etkin sayfalar</h3>
              <ul>
                {dataAnalytics.slice(0, 4).map((item, i) => (
                  <li key={i}>
                    <span>{item.key}</span>
                    <i>{item.value}</i>
                  </li>
                ))}
              </ul>
            </BoxList>}
          </Box>
          <Box>
            {/* <DetailButton /> */}
            <BoxTitle>Form</BoxTitle>
            <BoxData>
              <h2>{dataStage.map(m => m.value).reduce((a, b) => a + b, 0)}</h2>
              <p>toplam başvuru sayısı</p>
            </BoxData>
            <BoxList>
              <h3>Stage Durumu</h3>
              <ul>
                {dataStage.map((item ,i) => (
                  <li key={i}>
                    <span>{item.key}</span>
                    <i>{item.value}</i>
                  </li>
                ))}
              </ul>
            </BoxList>
          </Box>
          <Box>
            {/* <DetailButton /> */}
            <BoxTitle>Arama Datası</BoxTitle>
            <BoxData>
              <h2>{dataCallStatus.map(m => m.applicationCount).reduce((a, b) => a + b, 0)}</h2>
              <p>toplam telefon numarası</p>
            </BoxData>
            <BoxList>
              <h3>Arama Durumu</h3>
              <ul>
                {dataCallStatus.slice(0, 4).map((item, i) => (
                  <li key={i}>
                    <span>{item.name}</span>
                    <i>{item.applicationCount}</i>
                  </li>
                ))}
              </ul>
            </BoxList>
          </Box>
          <Box>
            {/* <DetailButton /> */}
            <BoxTitle>Tedarikçiler</BoxTitle>
            <BoxData>
              <h2>{dataSupplier.length}</h2>
              <p>toplam tedarikçi sayısı</p>
            </BoxData>
            <BoxList>
              <h3>Aktif Tedarikçi Listesi</h3>
              <ul>
                {dataSupplier.slice(0, 4).map((item, i) => (
                  <li key={i}>
                    <span>{item.name}</span>
                    <i>{item.applicationCount}</i>
                  </li>
                ))}
              </ul>
            </BoxList>
          </Box>
        </Item>
        <Item>
          <Box fluid>
            <DetailButton onClick={showDrawer} />
            <Row>
              <BoxTitle>shipfingo.com</BoxTitle>
            </Row>
            <Row>
              <BoxData>
                <h2>{dataPage.total}</h2>
                <p>toplam sayfa</p>
              </BoxData>
            </Row>
            <Row>
              <BoxData>
                <h2>{dataPage.activeCount}</h2>
                <p>etkin içerik sayfası</p>
              </BoxData>
            </Row>
            <Row>
              <BoxData>
                <h2>{dataPage.passiveCount}</h2>
                <p>pasif sayfa</p>
              </BoxData>
            </Row>
            <Row>
              <BoxData>
                <h2>{dataPage.deletedCount}</h2>
                <p>silinmiş sayfa</p>
              </BoxData>
            </Row>
          </Box>
        </Item>
      </Content>

      <Drawer
        open={drawerVisibility}
        onClose={() => setDrawerVisibility(false)}
        width={400}
        title={'shipfingo.com'}
      >
        <BoxData dark>
          <h2>62</h2>
          <p>etkin kullanıcı sayısı</p>
        </BoxData>
        <BoxList dark>
          <h3>Etkin sayfalar</h3>
          <ul>
            <li>
              <span>/elektrik-abone-numarasi</span>
              <i>6</i>
            </li>
            <li>
              <span>/klima-elektrik-tuketimi</span>
              <i>5</i>
            </li>
            <li>
              <span>/elektrik-faturasi-hesaplama</span>
              <i>4</i>
            </li>
            <li>
              <span>/elektrik-fiyatlari</span>
              <i>2</i>
            </li>
          </ul>
        </BoxList>
        <BoxList dark>
          <h3>Etkin sayfalar</h3>
          <ul>
            <li>
              <span>/elektrik-abone-numarasi</span>
              <i>6</i>
            </li>
            <li>
              <span>/klima-elektrik-tuketimi</span>
              <i>5</i>
            </li>
          </ul>
        </BoxList>
        <BoxList dark>
          <h3>Etkin sayfalar</h3>
          <ul>
            <li>
              <span>/elektrik-abone-numarasi</span>
              <i>6</i>
            </li>
          </ul>
        </BoxList>
      </Drawer>
    </Container>
  )
}
