import React, { useState } from 'react'
import dayjs from "dayjs"
import { DatePicker, Form, Input, Row, Col, Button, Select } from "antd";
import { SaveOutlined } from '@ant-design/icons'
import scheduledSmsTypes from './scheduled-sms-types'

const { TextArea } = Input;
const { Option } = Select;

export default function ScheduledSmsEdit({ formRef, onSubmit, ready, data }) {
    const [visibleParameter, setVisibleParameter] = useState(false)
    const [messageLength, setMessageLength] = useState(false)

    function onMessageChanged(e) {
        if (e.target.value.length > 600)
            setMessageLength(true)
        else
            setMessageLength(false)
    }

    return <>
        <Form form={formRef} layout="vertical" onFinish={onSubmit} size="large">
            <Form.Item name="isActive" style={{ display: 'none' }}>
                <Input />
            </Form.Item>
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Gönderim adı"
                        rules={[{ required: true, message: 'Lütfen gönderim adı yazınız.' }]}
                    >
                        <Input placeholder="Gönderim adı" />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="body"
                        label={<><span>Gönderim İçeriği</span> <Button className="parameter" onClick={() => setVisibleParameter(true)} type="link" style={{ marginLeft: 10 }}>Parametre kullan</Button></>}
                        rules={[{ required: true, message: 'Lütfen gönderim içeriği yazınız.' },
                        {
                            validator: (_, value) => {
                                if (messageLength && value.length > 600)
                                    return Promise.reject(new Error('Lütfen daha kısa bir içerik yazınız.'))
                                return Promise.resolve()
                            }
                        }]}
                    >
                        <TextArea showCount placeholder="Gönderim içeriği" rows={6} onChange={onMessageChanged} />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="groups"
                        label="Gruplar"
                        rules={[{ required: true, message: 'Lütfen grupları yazınız.' }]}
                    >
                        <Select placeholder="Gruplar" style={{ width: '100%' }} mode="multiple">
                            {scheduledSmsTypes.map((item) => (
                                <Option key={item.value} value={item.value}>
                                    {item.name} ({data.filter(m => m.type === item.value)[0].count})
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="datetime"
                        label="Gönderim zamanı"
                        rules={[{ required: true, message: 'Lütfen gönderim zamanı yazınız.' }]}
                    >
                        <DatePicker
                            format="DD.MM.YYYY HH:mm"
                            showTime={{ format: 'HH:mm' }}
                            showNow={false}
                            style={{ width: '100%' }}
                            disabledDate={(current) => current && current < dayjs().endOf('minute')}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button htmlType="submit" type="primary" style={{ width: "100%", marginTop: 20 }} onClick={() => formRef.submit()} disabled={!ready} loading={!ready}><SaveOutlined />Kaydet</Button>
                </Col>
            </Row>
        </Form>
    
    </>
}