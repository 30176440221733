import client from "../../configs/axios";
const DEFAULT_QUERY = '/schedules/sms'

export default class SettingService {
  getAll = params => client.get(`${DEFAULT_QUERY}/`, { params })
  getSmsGroups = params => client.get(`${DEFAULT_QUERY}/group-count`, { params })
  getById = id => client.get(`${DEFAULT_QUERY}/${id}`)
  test = model => client.put(`${DEFAULT_QUERY}/test`, model)
  addOrUpdate = model => model.id > 0 ? client.put(`${DEFAULT_QUERY}/`, model) : client.post(`${DEFAULT_QUERY}/`, model)
  update = model => client.put(`${DEFAULT_QUERY}/`, model)
  delete = model => client.delete(`${DEFAULT_QUERY}/`, { data: model })
}
