import React from 'react'
import { Button as AntButton } from 'antd'
import styled from 'styled-components'

export default function Button(props) {
  return <StyledButton {...props} />
}

const StyledButton = styled(AntButton)`
  .ant-btn-link {
    color: #3c4248;
  }
`
