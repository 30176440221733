const cdn = "https://static.shipfingo.com"

export const getOrigin = () => window.location.origin

export const getWebsite = () => {
  if (getOrigin().includes("localhost"))
    return `https://dev.shipfingo.com`

  return getOrigin().replace("office.","")
}

export const getAssetsUrl = () => `${cdn}/assets`

export const blobUrl = () => {
  if (getOrigin().includes("dev.") || getOrigin().includes("localhost"))
    return `${cdn}/dev/`
  if (getOrigin().includes("test."))
    return `${cdn}/test/`
  return `${cdn}/prod/`
}

export const getServiceOrigin = () => {
  if (getOrigin().includes("localhost"))
    return `${process.env.REACT_APP_API}/api`.replace("3500", "4000")

  //prod api address: https://office.shipfingo.com/api/*
  if (getOrigin().includes(".com"))
    return `${getOrigin()}/api`

  //dev-test api address: https://dev.shipfingo.com/api/*
  return `${getOrigin()}/api`.replace(".office", "")
}
