import React, { useState } from "react";
import { Drawer, Upload, Form, Input, Checkbox, Button, message } from 'antd';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';

import StorageService from "./StorageService";
const storageService = new StorageService();
const { Dragger } = Upload;

export default function StorageUpload({ visible, onClose, directoryPath, refreshStorage }) {
    const [ready, setReady] = useState(true);
    const [fileList, setFileData] = useState([]);
    const [directoryChecked, setDirectoryChecked] = useState(false);
    const [newPath, setNewPath] = useState(null);
    const [formRef] = Form.useForm()

    function onChange(info) {
        if (info.file.status === "removed") {
            setFileData([]);
        } else {
            setFileData([info.file]);
        }
        return false;
    }

    function uploadForm(form) {
        var files = fileList;
        if (files.length <= 0) {
            message.error("Lütfen dosya seçiniz.");
            return;
        }

        const fmData = new FormData();
        fmData.append("File", files[0]);
        fmData.append("Path", newPath !== null ? `${directoryPath}/${newPath}` : directoryPath);

        for (var data in form)
            if (form[data] !== undefined)
                fmData.append(data, form[data]);

        setReady(false);
        const progress = message.loading("Yükleniyor..", 0);
        storageService
            .write(fmData, (status, percent) => {
                if (!status) {
                    if (percent >= 99) {
                        message.info("Dosya kayıt ediliyor..");
                    }
                }
            })
            .then(x => {
                message.success("Yükleme başarılı.");
            })
            .catch(x => {
                message.error(x.response.data.errors[0].message)
            }).finally(() => {
                setReady(true)
                setFileData([])
                refreshStorage()
                setNewPath(null)
                formRef.resetFields()
                setDirectoryChecked(false)
                setTimeout(progress, 200)
            });
    }


    function beforeUpload() {
        return false;
    }

    return (
        <Drawer open={visible} onClose={onClose} title="Dosya Yükleme" width={350}>
            <Dragger 
            fileList={fileList} 
            height={150} 
            onChange={onChange} 
            beforeUpload={beforeUpload}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Dosya Seç / Sürükle</p>
            </Dragger>
            <Form form={formRef} layout="vertical" style={{ marginTop: 8 }} onFinish={uploadForm}>
                <Form.Item
                    name="title"
                    label="Başlık"
                >
                    <Input placeholder="Dosya başlığını yazınız." />
                </Form.Item>
                <Form.Item
                    name="description"
                    label="Açıklaması"
                >
                    <Input placeholder="Dosya açıklamasını yazınız." />
                </Form.Item>
                <Form.Item
                    name="newfolder"
                    valuePropName="checked"
                >
                    <Checkbox onChange={(e) => setDirectoryChecked(e.target.checked)}>Yeni klasör oluştur.</Checkbox>
                </Form.Item>
                <Form.Item
                    name="directoryName"
                    label="Klasör Adı"
                    hidden={!directoryChecked}
                    rules={[{ required: directoryChecked, message: "Lütfen klasör adını giriniz." }, { min: 3, message: "Klasör adı en az 3 karakterli olmalıdır." }]}
                    extra={
                        <span visible={!directoryChecked}>Geçerli Dizin : <strong>{directoryPath === "/" ? "/" : `/${directoryPath}/`}{newPath}</strong></span>
                    }
                >
                    <Input placeholder="Klasör adını yazınız." onChange={(e) => setNewPath(e.target.value)} />
                </Form.Item>

                <Form.Item>
                    <Button htmlType="submit" type="primary" icon={<UploadOutlined />} block={true} disabled={!ready} loading={!ready}>Yükle</Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}