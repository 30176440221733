import client from "../../configs/axios"
const DEFAULT_QUERY = '/storage'

export default class StorageService {
    getDirectories = params => client.get(`${DEFAULT_QUERY}/`, { params });
    getFiles = params => client.get(`${DEFAULT_QUERY}/files`, { params });
    delete = model => client.delete(`${DEFAULT_QUERY}/`, { data: model });
    update = params => client.put(`${DEFAULT_QUERY}/edit`, params);
    write = (model, callback) => client.put(`${DEFAULT_QUERY}/`, model, {
        headers: {
            "Content-Type": "multipart/form-data"
        },
        onUploadProgress: (event) => {
            const percent = Math.floor((event.loaded / event.total) * 100);
            if (percent === 100) {
                callback(true);
            } else {
                callback(false, percent);
            }
        }
    });
}
