import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, message } from 'antd'
import UserService from './UserService'

const userService = new UserService()

export default function PasswordEdit({ onSuccess, model }) {
  const [ready, setReady] = useState(true)
  const [formRef] = Form.useForm()

  useEffect(() => {
    if (!model) formRef.resetFields()
    else formRef.setFieldsValue(model)
  }, [model])

  const submit = (values) => {
    let _model = { ...values, id: model?.id }
    setReady(false)
    userService
      .updatePassword(_model)
      .then(() => {
        message.success('Şifreniz başarıyla değiştirildi')
        onSuccess({ mailAddress: _model.mailAddress })
        formRef.resetFields()
      })
      .catch((x) => {
        let error = x.response?.data.errors.find(
          (y) => y.code === 'AuthenticateFailedException'
        )
        if (error) {
          message.error('Şifrenizin eksik ya da hatalı olduğunu algıladık')
          return
        }
        message.error(
          'Kayıt sırasında bazı sorunlar yaşadık. Lütfen daha sonra tekrar deneyin.'
        )
      })
      .finally(() => setReady(true))
  }
  return (
    <Form form={formRef} layout="vertical" onFinish={submit} size="large" hideRequiredMark>
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form.Item
            label="Mevcut parola"
            name="oldPassword"
            rules={[
              { required: true, message: 'Lütfen şifrenizi girin' },
              { min: 6, message: 'Şifre en az 6 karakterli olmalıdır' },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Yeni parola"
            name="password"
            rules={[
              { required: true, message: 'Lütfen şifrenizi girin' },
              { min: 6, message: 'Şifre en az 6 karakterli olmalıdır' },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="Parola tekrar"
            name="repeatPassword"
            rules={[
              { required: true, message: 'Lütfen şifrenizi girin' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value)
                    return Promise.resolve()
                  return Promise.reject('Şifreler aynı olmalıdır')
                },
              }),
            ]}
            dependencies={['password']}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            style={{ width: '100%', marginTop: 20 }}
            onClick={() => formRef.submit()}
            disabled={!ready}
            loading={!ready}
          >
            Kaydet
          </Button>
        </Col>
      </Row>
    </Form>
  )
}
