import React, { useEffect, useState } from 'react'
import { Modal, message, Input, Row, Col, Button, Form } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import StorageService from './StorageService'
import FileBox from './FileBox'

const storageService = new StorageService()
const { Search } = Input

export default function StorageFiles({
  fileData,
  directoryPath,
  visibleUpload,
  refreshStorage,
  ready,
  pageType,
  setSelectedImage,
}) {
  const [fileList, setFileList] = useState([])
  const [fileSearchList, setFileSearchList] = useState([])

  const [previewFileUrl, setPreviewFileUrl] = useState(null)
  const [previewFilePath, setPreviewFilePath] = useState(null)
  const [previewFileId, setPreviewFileId] = useState(null)

  const [previewModelVisible, setPreviewModelVisible] = useState(false)
  const [removeFileModalVisible, setRemoveFileModalVisible] = useState(false)
  const [removeFileId, setRemoveFileId] = useState(null)
  const [formRef] = Form.useForm()

  useEffect(() => {

    getFiles()
  }, [directoryPath, fileData])

  function getFiles() {
    setFileList(fileData)
    setFileSearchList(fileData)
  }

  function onSearchFile(e) {
    let { value } = e.target
    if (value !== '') {
      let files = fileSearchList.filter(x => x.name.includes(value.toLocaleLowerCase('tr')))
      setFileList(files)
    } else
      setFileList(fileSearchList)
  }

  async function onPreview(file) {
    if (file.isDocument) {
      window.open(file.url)
      return
    }
    setPreviewFilePath(file.name || file.url.substring(file.url.lastIndexOf('/') + 1))
    setPreviewModelVisible(!file.isDocument)
    setPreviewFileUrl(file.previewUrl)
    setPreviewFileId(file.uid)
    formRef.setFieldsValue(file)
  }

  function onRemove() {
    setRemoveFileId(previewFileId)
    setRemoveFileModalVisible(true)
  }

  function updateImage() {
    Promise.allSettled([formRef.validateFields()])
      .then(([result]) => {
        if (result.status !== 'fulfilled') {
          message.error(result.reason.errorFields[0].errors[0])
          return
        }

        const progress = message.loading('Kaydediliyor..', 0)
        let params = {
          id: previewFileId,
          title: formRef.getFieldsValue().title,
          description: formRef.getFieldsValue().description
        }
        storageService.update(params).then((x) => {
          message.success('Bilgiler başarılı bir şekilde kaydedildi.')
          getFiles()
        }).catch((x) => {
          message.error(x.response?.data?.errors[0]?.message ?? 'Kaydetme başarısız.')
        })
          .finally((x) => {
            setTimeout(progress, 200)
            refreshStorage()
          })
      })
      .catch(err => {
        console.log(err)
        message.error("Lütfen tablerdeki zorunlu alanları doldurunuz.")
      })
  }

  function onRemoveModalConfirm() {
    setRemoveFileModalVisible(false)
    setPreviewModelVisible(false)
    const progress = message.loading('Siliniyor..', 0)
    storageService
      .delete({ id: removeFileId })
      .then((x) => {
        message.success('Silme başarılı.')
        getFiles()
      })
      .catch((x) => {
        message.error('Silme başarısız.')
      })
      .finally((x) => {
        setTimeout(progress, 200)
        refreshStorage()
      })
  }

  function selectImage() {
    if (pageType !== 'page') {
      if (pageType === 'drawer') {
        setSelectedImage({ url: previewFileUrl, id: previewFileId })
      } else {
        window.parent.postMessage(previewFileUrl, '*')
      }
      setPreviewModelVisible(false)
    }
  }

  return (
    <PicturesWall style={{ display: ready ? 'block' : 'none' }}>
      <Row gutter={8}>
        <Col xs={{ span: 24 }} lg={{ span: 20 }}>
          <Search
            className="ensearch"
            size="large"
            placeholder="Ara"
            onChange={onSearchFile}
            disabled={!ready}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 4 }}>
          <Button
            key="add"
            type="primary"
            onClick={() => visibleUpload()}
            disabled={!ready}
            block
          >
            <PlusOutlined /> Dosya Ekle
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 8 }}>
        <Col className="file__list">
          <Modal
            okText="Onayla"
            cancelText="Vazgeç"
            onOk={onRemoveModalConfirm}
            open={removeFileModalVisible}
            onCancel={() => setRemoveFileModalVisible(false)}
          >
            Dosyayı silmek istediğinize emin misiniz?
          </Modal>
          {fileList.map((x, i) => <FileBox
            key={i}
            title={x.name}
            fileData={x}
            src={x.previewUrl}
            onVisibleChange={() => onPreview(x)} />)}
          <Modal
            open={previewModelVisible}
            title={previewFilePath}
            footer={null}
            onCancel={() => setPreviewModelVisible(false)}
          >
            <img alt="" style={{ width: '100%' }} src={previewFileUrl} />
            <Form form={formRef} layout="vertical" style={{ marginTop: 8 }}>
              <Form.Item
                name="title"
                label="Başlık"
                rules={[{ required: true, message: 'Lütfen başlık yazınız' }]}
              >
                <Input placeholder="Dosya başlığını yazınız." />
              </Form.Item>
              <Form.Item
                name="description"
                label="Açıklaması"
                rules={[{ required: true, message: 'Lütfen açıklama yazınız' }]}
              >
                <Input placeholder="Dosya açıklamasını yazınız." />
              </Form.Item>
            </Form>
            <Button
              type="primary"
              onClick={updateImage}
              block={true}
              style={{ marginTop: 24, display: 'block' }}
            >
              Kaydet
            </Button>
            <Button
              type="primary"
              onClick={selectImage}
              block={true}
              style={{ marginTop: 24, display: pageType !== 'page' ? 'block' : 'none' }}
            >
              Seç
            </Button>
            <Button
              type="primary"
              onClick={onRemove}
              block={true}
              style={{ marginTop: 24, display: 'block' }}
            >
              Sil
            </Button>
          </Modal>
        </Col>
      </Row>
    </PicturesWall>
  )
}

const PicturesWall = styled.div`
  .ant-upload-list-picture .ant-upload-list-item,
  .ant-upload-list-picture-card .ant-upload-list-item {
    border: 1px solid #1890ff;
    padding: 0px;
  }

  .ant-upload-list-picture-card .ant-upload-list-item-info::before {
    background-color: rgb(64 169 255 / 30%);
  }

  .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
    padding: 6px;
  }

  .ant-upload.ant-upload-select-picture-card {
    background-color: #1890ff;

    .ant-upload {
      color: #fff;
    }
  }
`
