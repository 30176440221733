import jwtDecode from "jwt-decode";
import { useLocalStorage } from "./useLocalStorage";

export function useLocalUser() {
    const [user, setUser] = useLocalStorage("user")
    const getValue = () =>{
        if(!user?.access_token)
          return null
        let jwt = jwtDecode(user.access_token)
        const isAuthenticated = jwt && !(jwt?.exp < Date.now() / 1000)
        const isSupplier = isAuthenticated && (jwt?.role === "supplier" || (Array.isArray(jwt.role) && jwt?.role.filter(x => x !== "supplier")?.length === 0))
        const isAuthor = isAuthenticated && (jwt?.role === "author" || (Array.isArray(jwt.role) && jwt?.role.filter(x => x !== "author")?.length === 0))
        const isOfficeUser = isAuthenticated && (jwt?.role === "user" || (Array.isArray(jwt.role) && jwt?.role.filter(x => x !== "user")?.length > 0))
        return {
            ...user,
            isAuthenticated,
            isSupplier,
            isAuthor,
            isOfficeUser,
        }
    }
    return [getValue(), setUser]
}