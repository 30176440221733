import { blobUrl } from "../../configs/origins";

const storageFileTypes = [
    {
        extension:".pdf",
        icon:`${blobUrl()}content/other/pdf-icon.png`,     
    },
    {
        extension:".xls",
        icon:`${blobUrl()}content/other/excel-icon.png`,     
    },
    {
        extension:".xlsx",
        icon:`${blobUrl()}content/other/excel-icon.png`,     
    },
    {
        extension:".doc",
        icon:`${blobUrl()}content/other/word-icon.png`,     
    },
    {
        extension:".docx",
        icon:`${blobUrl()}content/other/word-icon.png`,     
    },
    {
        extension:".ppt",
        icon:`${blobUrl()}content/other/powerpoint-icon.png`     
    },
    {
        extension:".pptx",
        icon:`${blobUrl()}content/other/powerpoint-icon.png`     
    },
];
export default storageFileTypes;
