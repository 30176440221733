import {useNavigate, useLocation} from "react-router-dom";
import {urlDeserializer, urlSerializer} from "../configs/axios";
import {useEffect, useState} from "react";

export function useCustomQueryStringOperations(filter, menu = "", subMenu = "") {
    const navigate = useNavigate();
    const location = useLocation();
    const [jsonifyString, setJsonifyString] = useState(null);
    const setReplaceHistory = () => {
        try {
            navigate({search: `${urlSerializer(filter)}&menu=${menu}&subMenu=${subMenu}`}, { replace: true })
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(()=>{
        try {
            let jsonifyQueryString = urlDeserializer(location.search);
            jsonifyQueryString.pageIndex = parseInt(jsonifyQueryString.pageIndex);
            jsonifyQueryString.pageSize = parseInt(jsonifyQueryString.pageSize);
            if(jsonifyQueryString.supplierId)
                jsonifyQueryString.supplierId = parseInt(jsonifyQueryString.supplierId);
            if(jsonifyQueryString.cityId)
                jsonifyQueryString.cityId = parseInt(jsonifyQueryString.cityId);
            if(jsonifyQueryString.month)
                jsonifyQueryString.month = parseInt(jsonifyQueryString.month);
            if(jsonifyQueryString.isDeleted)
                jsonifyQueryString.isDeleted = jsonifyQueryString.isDeleted === "true";
            if(jsonifyQueryString.isActive)
                jsonifyQueryString.isActive = jsonifyQueryString.isActive === "true";

            setJsonifyString(jsonifyQueryString);
        } catch (error) {
            console.log(error,"e");
        }
    },[])
    return [navigate, location, jsonifyString, setReplaceHistory];
}