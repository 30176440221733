import React, { useEffect, useState } from 'react'
import { Table, Drawer, Popconfirm, Button, Input, message, Row, Col, Form } from 'antd'
import { PlusOutlined, DeleteOutlined, RetweetOutlined } from '@ant-design/icons'
import Page from "../../components/layout/Page"
import ScheduleService from './ScheduleService'
import ScheduledSmsEdit from './ScheduledSmsEdit'
import scheduledSmsColumns from "./schedule-sms-columns"
import { MT20 } from '../../styles/global'
import ActiveDeletedFilter from '../../components/ActiveDeletedFilter'
import ScheduledSmsTest from './ScheduledSmsTest'
import dayjs from 'dayjs'
import { useCustomQueryStringOperations } from "../../hooks/useCustomQueryStringOperations";

const service = new ScheduleService()
const { Search } = Input

export default function ScheduledSms() {
    const [ready, setReady] = useState(false)
    const [data, setData] = useState(null)
    const [smsGroupCount, setGroupCount] = useState([])
    const [filter, setFilter] = useState({ orderBy: "id_desc", pageIndex: 1, pageSize: 10, isDeleted: false, isActive: undefined })
    const [, location, jsonifyQueryString, customHistoryReplace] = useCustomQueryStringOperations(filter, "", "ScheduledSms");
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [current, setCurrent] = useState(null)
    const [drawerVisibility, setDrawerVisibility] = useState(false)
    const [searchText, setSearchText] = useState(null);
    const [drawerTestVisibility, setDrawerTestVisibility] = useState(false)
    const [formRef] = Form.useForm()
    const [formTestRef] = Form.useForm()


    useEffect(() => {
        if (jsonifyQueryString) {
            if (location.search !== "") {
                setSearchText(jsonifyQueryString.search);
                setReady(true)
                setFilter(jsonifyQueryString)
            } else {
                customHistoryReplace();
                setSelectedRowKeys([])
                load()
                loadSmsGroup()
            }
        }
    }, [jsonifyQueryString])


    useEffect(() => {
        if (ready) {
            customHistoryReplace();
            setSelectedRowKeys([])
            load()
            loadSmsGroup()
        }
    }, [filter])

    //reload page data
    function load(queries = filter) {
        setReady(false)
        service.getAll(queries || filter)
            .then(x => setData(x.data)).catch(() => {
                message.error("SMS gönderim planları yüklenemedi.")
            }).finally(() => {
                setReady(true)
            })
    }

    function loadSmsGroup() {
        setReady(false)
        service.getSmsGroups()
            .then((x) => {
                setGroupCount(x.data)
            })
            .catch(() => {
                message.error("SMS grupları yüklenemedi.")
            }).finally(() => {
                setReady(true)
            })
    }

    //deletes one row
    function remove() {
        setReady(false)
        service.delete({ ids: selectedRowKeys }).then(() => {
            message.success(filter.isDeleted ? "SMS gönderim planı geri yüklendi." : "SMS gönderim planı silindi.")
            setSelectedRowKeys([])
            load()
        }).catch(() => {
            message.error(filter.isDeleted ? "SMS gönderim planı geri yüklenemedi." : "SMS gönderim planı silinemedi.")
            setReady(true)
        })
    }

    function edit(row) {
        setDrawerVisibility(true)
        formRef.setFieldsValue(row)
        formRef.setFieldsValue({ datetime: dayjs(row.dateTime) })
        setCurrent(row)
    }

    function add() {
        setDrawerVisibility(true)
        formRef.resetFields()
        setCurrent({ id: 0 })
    }

    function test(row) {
        setDrawerTestVisibility(true)
        formTestRef.setFieldsValue(row)
        setCurrent(row)
    }

    const submitTest = model => {
        setReady(false)
        setDrawerTestVisibility(false)
        service.test({ ...model, id: current.id })
            .then(x => {
                message.success("SMS testi gönderildi.")
                setReady(true)
            })
            .catch(err => {
                message.error(err.response?.data?.errors[0]?.message ?? 'Hata oldu.')
                setReady(true)
            })
    }

    const submit = model => {
        setReady(false)
        setDrawerVisibility(false)
        service.addOrUpdate({ ...model, id: current.id, dateTime: model.datetime.local().format("YYYY-MM-DD HH:mm:ss") })
            .then(x => {
                message.success("SMS gönderim planı kaydedildi.")
                setReady(true)
                load()
                setSelectedRowKeys([])
            })
            .catch(err => {
                message.error(err.response?.data?.errors[0]?.message ?? 'Hata oldu.')
                setReady(true)
            })
    }

    async function activeChanged(val, row) {
        const _rows = data?.result.map(element => {
            if (element.id === row.id) {
                element.loading = true
                element.isActive = val
            }
            return element
        });
        setData(p => ({ ...p, result: _rows }))
        await service.addOrUpdate(_rows.find(x => x.id === row.id))
        _rows.find(x => x.id === row.id).loading = false
        setData(p => ({ ...p, result: _rows }))
    }

    function rowClicked(rowKey) {
        let newSelectedRowkeys = [...selectedRowKeys];
        if (newSelectedRowkeys.find(x => x === rowKey) === undefined)
            newSelectedRowkeys.push(rowKey)
        else
            newSelectedRowkeys = newSelectedRowkeys.filter(x => x !== rowKey)
        setSelectedRowKeys(newSelectedRowkeys)
    }

    return <Page
        title="Toplu SMS Gönderim Planları"
        extra={[
            <Button disabled={!ready} key="add" type="primary" onClick={add}><PlusOutlined />Yeni ekle</Button>,
            <Popconfirm key="deleteConfirm"
                title={`Toplam ${selectedRowKeys.length} kayıt seçtiniz. İşleme devam etmek istediğinize emin misiniz?`}
                onConfirm={remove}
                okText="Evet"
                cancelText="Hayır"
            >
                {selectedRowKeys.length > 0 && filter.isDeleted && (
                    <Button disabled={!ready} key="remove" type="primary" style={{ backgroundColor: "green" }} ><RetweetOutlined /> Geri Getir</Button>)}
                {selectedRowKeys.length > 0 && !filter.isDeleted && (
                    <Button disabled={!ready} key="remove" type="danger" ><DeleteOutlined /> Sil</Button>
                )}
            </Popconfirm>
        ]}>
        <Row gutter={16}>
            <Col>
                <Search className="ensearch" size="large" disabled={!ready} allowClear onChange={e => setSearchText(e.target.value ? e.target.value : null)} value={searchText} enterButton onSearch={val => setFilter({ ...filter, pageIndex: 1, search: val === "" ? null : val })} key="search" style={{ width: 200, marginRight: 10 }} placeholder="Ara" />
            </Col>
            <Col>
                <ActiveDeletedFilter filter={filter} onFilter={setFilter} />
            </Col>
        </Row>
        <MT20 />
        <Table
            rowKey="id"
            dataSource={data?.result}
            columns={scheduledSmsColumns(edit, activeChanged, test)}
            rowSelection={{
                columnWidth: 40,
                selectedRowKeys,
                onChange: vals => setSelectedRowKeys(vals)
            }}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => { rowClicked(record.id) }, // click row
                    onDoubleClick: event => { edit(record) }
                };
            }}
            loading={!ready}
            pagination={{
                pageSize: filter?.pageSize,
                total: data?.rowCount,
                showTotal: (total, range) => `Toplam ${total} kayıt var`,
                current: filter?.pageIndex,
                position: ["bottomCenter"]
            }}
            onChange={(f, _, s) => (s.order !== undefined 
                ? setFilter(p => ({ ...p, pageIndex: f.current, orderBy: `${s.columnKey}_${s.order}` })) 
                : setFilter(p => ({ ...p, pageIndex: f.current, orderBy: "id_asc" }))
            )}
        />
        <Drawer open={drawerVisibility} onClose={() => { setDrawerVisibility(false); setSelectedRowKeys([]) }} width={600} title="SMS Gönderim Planı">
            <ScheduledSmsEdit data={smsGroupCount} formRef={formRef} onSubmit={submit} ready={ready} />
        </Drawer>
        <Drawer open={drawerTestVisibility} onClose={() =>{ setDrawerTestVisibility(false); setSelectedRowKeys([]) }} width={350} title="SMS test gönderimi">
            <ScheduledSmsTest formRef={formTestRef} onSubmit={submitTest} ready={ready} />
        </Drawer>
    </Page>
}
