import React from "react"
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from "antd"
import dayjs from 'dayjs'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import trTR from 'antd/locale/tr_TR'
import reportWebVitals from "./reportWebVitals"
import { GlobalStyle } from "./styles/global"
import BaseLayout from "./components/layout/BaseLayout"
import PrivateRoute from "./components/PrivateRoute"
import Page403 from "./components/403"
import Page404 from "./components/404"

import Login from "./pages/account/Login"
import ForgotPassword from "./pages/account/ForgotPassword"
import Dashboard from "./pages/dashboard/DashBoard"
import Users from "./pages/account/Users"
import Storages from "./pages/storages/Storages"
import StoragePanel from "./pages/storages/StoragePanel"
import Sms from "./pages/templates/Sms"
import Emails from "./pages/templates/Emails"
import ScheduledSms from "./pages/smsSchedules/ScheduledSms"
import ScheduledSmsEdit from "./pages/smsSchedules/ScheduledSmsEdit"



function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<PrivateRoute />}>
          <Route element={<BaseLayout />}>
            <Route exact path="/" element={<Dashboard />} />



            <Route exact path="/settings" />
            <Route exact path="/settings/ScheduledSms" element={<ScheduledSms />} />
            <Route exact path="/settings/ScheduledSms/:id" element={<ScheduledSmsEdit />} />


            <Route exact path="/storages" element={<Storages />} />
            <Route exact path="/storage/panel" element={<StoragePanel />} />
            <Route exact path="/templates/sms" element={<Sms />} />
            <Route exact path="/templates/email" element={<Emails />} />
            <Route exact path="/users" element={<Users />} />

          </Route>

          <Route exact path="/forbidden" element={<Page403 />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<React.StrictMode>
    <ConfigProvider locale={trTR}>
      <App />
    </ConfigProvider>
  </React.StrictMode>);
 
require('dayjs/locale/tr')
dayjs.extend(require('dayjs/plugin/relativeTime'))
dayjs.extend(require('dayjs/plugin/localizedFormat'))
dayjs.extend(require('dayjs/plugin/utc'))
dayjs.locale('tr')
reportWebVitals()
