import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import { getServiceOrigin } from './origins';

export function createSocketConnection(token) {
  if (!token)
    return
  const connection = new HubConnectionBuilder()
    .withUrl(`${getServiceOrigin()}/hubs/application`, { accessTokenFactory: () => token })
    .configureLogging(LogLevel.Information)
    .withAutomaticReconnect()
    .build()

  return connection
}

