import styled, { createGlobalStyle } from "styled-components"
import colors from "./colors";
export const MT20 = styled.div`margin-top:20px;`
export const GlobalStyle = createGlobalStyle`
  #root {
      overflow: hidden;
      width: 100%;
  }

  .ant-page-header .ant-page-header-heading-left {
      align-items: start !important;
  }

  @media only screen and (max-width: 1024px) { 
    .ant-layout-content {
      overflow: hidden;
      overflow-x: scroll;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      width: 100%;
    }
    .ant-page-header {
      width: 100%;
      min-width: 800px;
      max-width: 1024px;
    }
  }
  

  //Layout
  .ant-form-item-label{
    color: black;
  }
  .ant-form-item {
    margin-bottom: 4px !important;
  }
  td.ant-table-cell-fix-right-first {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .ant-breadcrumb + .ant-page-header-heading {
    margin-top: 30px;
  }
  .ant-page-header-heading {
    flex-wrap: wrap;
  }
  .ant-page-header-heading .ant-btn {
    margin-bottom: 0;
  }
  .ant-page-header-heading-extra {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    width: auto;
  }
  .ant-page-header-heading-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ant-result-extra {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    width: 100%;
  }

  //Notification
  .ant-notification-notice {
    border-radius: 14px;
  }

  
  //Text
  .ant-page-header-heading-title {
    font-size: 33px;
    line-height: 42px;
    color: ${colors.sapphire};
  }
  .ant-page-header-heading-sub-title {
    font-size: 12px;
    line-height: 19px;
    font-weight: 300;

  }

  //Tags
  .entag {
    display: inline-block;

    padding: 3px 6px;

    min-width: 76px;
    max-width: 92px;

    background-color: #E1EBFF;
    border-radius: 3px;
    border: .5px solid #d1dcef;
    text-align: center;
    color: #5576a3;

    &.canceled {
      background-color: #d52638;
      border-color:#bb1222;
      color: #fff;
    }
    &.approved {
      background-color: #5EC488;
      border-color:#47bb76;
      color: #fff;
    }
    &.pending {
      background-color: #fdc236;
      border-color: #f3b727;
      color: #fff;
    }
    }
    &.notreached {
      background-color: #ff4d4f;
      border-color: #e52627;
      color: #fff;
    }
  }

  //Parameters
  .parameter-kind {
    display: flex;

    padding: 3px 6px;

    min-width: 76px;
    max-width: 92px;

    background-color: #E1EBFF;
    border-radius: 3px;
    border: .5px solid #d1dcef;
    text-align: center;
    color: #5576a3;
    align-content: space-between;
    justify-content: flex-start;
    flex-direction: column;
    margin: auto;

    &.insurance {
      background-color: #e17b80;
      border-color: #e17b80;
      color: #fff;
      opacity: 0.9;
      border-radius: 10px;    
    }
    &.finance {
      background-color: #82d0a2;
      border-color:#82d0a2;
      color: #fff;
      opacity: 0.9;
      border-radius: 10px;
    }
    &.energy {
      background-color: #9cc8f1;
      border-color: #9cc8f1;
      color: #fff;
      opacity: 0.9;
      border-radius: 10px;
    }
  }

  //Button
  .ant-btn {
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    margin-bottom: 4px;
    padding: 10px 20px;

    height: 44px;

    background: rgba(255, 255, 255, 0.4);
    border: 0.8px solid ${colors.echoBlue};
    border-radius: 6px;

    font-size: 14px;
    line-height: 19px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: ${colors.bunting};

    cursor: pointer;

    transition: border-color 300ms linear, color 250ms linear;

    &::after {
      content: '';
      position: absolute;
      top: -1px;
      left: -110%;
      z-index: -1;
      width: 100%;
      height: 110%;
      border-radius: 100px;
      background-color: ${colors.bunting};
      transition: left 300ms ease-out, border-radius 300ms ease-out;
    }
    &:hover,
    &:focus {
      border-color: ${colors.bunting};
      color: ${colors.white};
      &::after {
        left: 0;
        border-radius: 6px;
      }
    }
  }
  .ant-btn-primary {
    background-color: ${colors.violetRed};
    border: 0;
    border-radius: 6px;

    color: ${colors.white};

    &::after {
      border-radius: 100px;
      background-color: ${colors.violetRedHover};
    }
    &:hover,
    &:focus {
      background-color: ${colors.violetRed};
      border-color: ${colors.violetRedHover};
      color: ${colors.white};
      &::after {
        left: 0;
        border-radius: 6px;
      }
    }

  }
  .ant-btn-danger {
    background-color: ${colors.error};
    border: 0;
    border-radius: 6px;

    color: ${colors.white};

    &::after {
      border-radius: 100px;
      background-color: ${colors.wait};
    }
    &:hover,
    &:focus {
      background-color: ${colors.error};
      border-color: ${colors.wait};
      color: ${colors.white};
      &::after {
        left: 0;
        border-radius: 6px;
      }
    }
  }
  .ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
    pointer-events: none;
  }
  a.parameter {
    margin: 0;
    padding: 4px 8px;

    border: 0;
    border-radius: 4px;
    color: ${colors.bunting};
    font-weight: bold;
    font-size: 14px;
  }
  .ant-btn.parameter {
    margin: 0;
    padding: 4px 8px;

    border: 0;
    border-radius: 4px;

    &:hover {
      text-decoration: underline;
      color: ${colors.bunting};
    }

    &::after {
        content: none !important;
      }
  }
  .ant-btn-text {
    padding: 8px;
     
    width: auto;
    height: auto;


    border: 0;
    background: transparent;

    &::after, &::before {
      content: none;
    }
    &:hover, &:focus{
      color: ${colors.white};
      background-color: #BFC7E3;
    }
  }



  .content-label .ant-col.ant-form-item-label {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
  }
  .content-label .ant-form-item-required::before {
    position: absolute;
  }
  .content-label .ant-form-item-required span {
    padding-left: 8px;
  }
  .content-label .ant-col.ant-form-item-label .ant-form-item-required {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;

    text-align: left;
  }

  //Select Box
  .selectbox {
    :not(.ant-select-customize-input) .ant-select-selector {
      padding: 5px 11px !important;

      height: 50px !important;

      background-color: ${colors.white};
      border: 0.5px solid ${colors.selectBorder};
      border-radius: 6px;
    }
  }
  


  //colors
  .ant-switch-checked {
    background-color: ${colors.success};
  }
  .anticon.anticon-check, .anticon.anticon-close {
    margin-top: 5px;
  }
  .ensearch {
    padding: 4px;
    border: 1px solid ${colors.border};

    background: ${colors.white};
    border-radius: 6px;
    
    .ant-input-affix-wrapper {
      height: 40px;
      border: 0;
    }
    .ant-input-group-addon, .ant-btn-primary {
      border: 0;
    }
    .ant-input-search-button, .ant-btn-primary {
      margin-bottom: 0;
      background-color: ${colors.white};
      border-color: ${colors.white};
      box-shadow: none !important;

      &::after {
        content: none !important;
      }
    }
    .ant-input-group-addon {
      background: transparent;
    }
    .anticon.anticon-search svg {
      fill: ${colors.smalt};
      transform: scale(1.5);
    }
    .ant-input-affix-wrapper-focus, .ant-input-affix-wrapper-focused {
      border: 0;
      box-shadow: none;
    }
  }

  // Radio Group
  .ant-radio-group-large .ant-radio-button-wrapper {
    padding: 0 21px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.bunting};
  }
  .ant-radio-button-wrapper {
    background: transparent;
    border: 0;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
  }
  .ant-radio-button-wrapper:first-child {
    border-radius: 0;
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
  .ant-radio-button-wrapper:hover,
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    position: relative;
    background: transparent;
    border: 0;
    color: ${colors.sapphire};

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 5%;
      
      width: 90%;
      height: 3px;

      background-color: ${colors.royalBlue};
      border-radius: 3px;
    }
  }
  .ant-radio-button-wrapper-disabled {
    background: transparent;
    border: 0;
    color: ${colors.sapphire};
    opacity: .6;
    &::after {
      content: none !important;
    }
  }
  .ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
  .ant-radio-button-wrapper:first-child {
    border-left: 0;
  }
  @media only screen and (max-width: 600px) {
    .ant-drawer-wrapper-body {
      padding: 10px;
    }
  }

  .application__table .ant-table-tbody > tr > td {
    padding: 4px;
  }

  //Datepicker
  .enpicker .ant-space-item {
    width: 100%;
    height: 100%;
  }
  .enpicker.ant-picker {
    margin: 0 !important;
    padding: 0 10px;

    width: 100%;
    max-width: 100%;
    height: 50px;

    background-color: #fff;
    border: 1px solid ${colors.border};
    border-radius: 6px;

    font-size: 12px;
    text-align: left;
    color: #5c5c5c;

    @media only screen and (min-width: 1024px) {
      max-width: 223px;
    }
  }
  .enpicker .ant-picker-input > input {
    font-size: 12px;
    color: #5c5c5c;
  }
  .enpicker .ant-picker-suffix {
    width: 24px;
    min-width: 24px;
    height: 24px;

    background: transparent url('/assets/svg/calendar.svg') no-repeat center center;
  }
  .enpicker .anticon.anticon-calendar {
    display: none;
  }
  .enpicker .ant-picker-range .ant-picker-active-bar {
    border-radius: 2px;
    background-color: #3e73df;
  }
  .enpicker .ant-picker-range .ant-picker-clear {
    top: 53%;
    right: 16px;

    color: #5c5c5c;
  }
  .file__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
  }

  .ant-input-textarea-show-count:after{
    content: attr(data-count) "/600" !important;
  }

  .ant-popconfirm-buttons{
    min-height: 50px;

    .ant-btn{
      float: left;
    }
  }
`;
