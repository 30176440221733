import dayjs from "dayjs"
import { Switch, Tooltip } from "antd"
import Button from "../../components/Button"
import { EditOutlined, RocketOutlined } from '@ant-design/icons'

export default function scheduledSmsColumns(edit, activeChanged, test) {
    return [
        {
            fixed: "left",
            title: 'Adı',
            dataIndex: 'name',
            width: 200,
            sorter: (a, b) => { },
            key: 'name'
        },
        {
            title: 'Gruplar',
            dataIndex: 'groups',
            key: 'groups',
            render: v => v.join(', ')
        },
        {
            title: 'Gönderim Tarihi',
            dataIndex: 'dateTime',
            key: 'dateTime',
            width: 150,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => { },
            render: v => dayjs(v).format("LLL")
        },
        {
            title: 'Gönderildi Mi',
            dataIndex: 'sent',
            key: 'sent',
            sortDirections: ['descend', 'ascend'],
            width: 90,
            sorter: (a, b) => { },
            render: v => v ? "Evet" : "Hayır"
        },
        {
            title: 'Son İşlem Yapan',
            dataIndex: 'lastModifierUser',
            key: 'lastModifierUser',
            width: 120,
            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.lastModifierUser?.localeCompare(b.lastModifierUser, 'tr', { sensivity: 'base' })
        },
        {
            title: "Aktif / Pasif",
            dataIndex: "isActive",
            align: "center",
            width: 110,
            render: (v, row) => dayjs(row.dateTime) > dayjs()
                ? <Switch checkedChildren="Aktif" unCheckedChildren="Pasif" checked={v} loading={row.loading} onChange={val => activeChanged(val, row)} />
                : v ? "Aktif" : "Pasif"
        },
        {
            title: "İşlemler",
            key: "actions",
            fixed: "right",
            width: 90,
            render: (v, row) => <>
                <Button key="edit" type="primary" onClick={() => edit(row)}><EditOutlined /></Button>
                {dayjs(row.dateTime) > dayjs() && <Tooltip placement="left" title="Test">
                    <Button key="test" type="link" onClick={() => test(row)}><RocketOutlined /></Button></Tooltip>
                }
            </>
        }
    ]
}