import React, { useRef } from 'react'
import { Editor as TEditor } from '@tinymce/tinymce-react'
import { getOrigin, blobUrl } from '../configs/origins'
import colors from '../styles/colors'

import {
  Default,
  Heading,
  CTAEnergy,
  CTAFinance,
  CTAInsurance,
  Highlighting,
  Accordion,
  AccordionItem,
  List,
  TabPanel,
  TabPanelTitle,
  TabPanelDetail,
  SubPages,
  ComparisonCorporate,
  ComparisonIndustry,
  CalculateKwh,
  LineChart,
  PieChart,
  BarChart,
  MostPreferred,
  Campaign,
  CalculateDeposit,
  CalculateCarTax,
  PersonalDailyCreditInterestTable,
  ResidenceDailyCreditInterestTable,
  VehicleDailyCreditInterestTable,
  StickyMain,
  StickyEnergy,
  StickyFinance,
  StickyInsurance,
  CalculateCreditByBank,
  CalculateLoanPaymentPlan,
  FormulaWidget, MostPreferredCreditCard
} from './EditorTemplates'
import { customCleanHTML } from "../utils/customCleanHTML";


export default function Editor({ value, init, onChange = (value) => { } }) {
  const editorRef = useRef(null)

  function filePickerCallBack(callback, value, meta) {
    const opener = this.windowManager.openUrl({
      url: `${getOrigin()}/storage/panel`,
      title: 'Bir resim seçin',
    })
    window.addEventListener('message', function (event) {
      callback(event.data, { text: 'My text' })
      opener.close()
    })
  }

  return (
      <TEditor
        value={value}
        onInit={(event, editor) => {
          editorRef.current = editor
        }}
        init={{
          height: 500,
          menubar: true,
          plugins: [
            ' advlist anchor autolink codesample fullscreen help image imagetools',
            ' lists link media noneditable preview',
            ' searchreplace table template visualblocks wordcount code hr paste',
          ],
          toolbar:
            'insertfile a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify hr | bullist numlist | link image tinydrive code',
          file_picker_callback: filePickerCallBack,
          noneditable_editable_class: 'editable',
          noneditable_noneditable_class: 'nonEditable',
          templates: [
            Default,
            Heading,
            MostPreferred,
            MostPreferredCreditCard,
            StickyMain,
            StickyEnergy,
            StickyFinance,
            StickyInsurance,
            CTAEnergy,
            CTAFinance,
            CTAInsurance,
            Highlighting,
            Accordion,
            AccordionItem,
            List,
            TabPanel,
            TabPanelTitle,
            TabPanelDetail,
            SubPages,
            FormulaWidget,
            ComparisonCorporate,
            ComparisonIndustry,
            CalculateKwh,
            LineChart,
            BarChart,
            PieChart,
            Campaign,
            PersonalDailyCreditInterestTable,
            ResidenceDailyCreditInterestTable,
            VehicleDailyCreditInterestTable,
            CalculateLoanPaymentPlan,
            CalculateCreditByBank,
            CalculateDeposit,
            CalculateCarTax
          ],
          content_style: contentStyle,
          ...init,
          force_p_newlines: true,
          forced_root_block: 'p',
          pagebreak_separator: '<div style = "display:block; clear:both; page-break-after:always;"></div>',
          paste_postprocess: function (plugin, args) {
            args.node.innerHTML = customCleanHTML(args.node.innerHTML);
          },
          entity_encoding: 'raw',
        }}
        onEditorChange={(content, editor) => {
          onChange(content)
        }}
      />
  )
}

const contentStyle = `
  .content__item[data-template='comparison-corporate'], .content__item[data-template='comparison-industry'], [data-template='accordion'], [data-template='accordion-item'], [data-template='list'] {
    position: relative;
    border: 1px solid red;
    border-radius: 3px;
  }

  .content__item[data-template='comparison-corporate']::before, .content__item[data-template='comparison-industry']::before, [data-template='accordion']::before, [data-template='accordion-item']::before, [data-template='list']::before {
    position: absolute;
    right: 0px;
    top: 0px;

    background-color: red;
    padding: 0 8px;
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    color: ${colors.white};
  }

  [data-template='accordion'],
  [data-template='accordion-item'] {
    padding-left: 4px;
    margin: 34px 6px 6px 6px;
  }

  .template-refactor {
    overflow: hidden;

    padding: 4px 4px 4px 4px;

    background: ${colors.echoBlue};
    border: 1px solid ${colors.echoBlueDark};
    border-radius: 6px;
  }

  .template-refactor[data-title="default"]::before { content: "Varsayılan Şablon"; }
  .template-refactor[data-title="formula"]::before { content: "Formül Şablonu"; }
  .template-refactor[data-title="credit-calculate-by-bank"]::before { content: "Bankaya Göre Kredi Hesaplama Şablonu"; }
  .template-refactor[data-title="personalDailyCreditInterest"]::before { content: "İhtiyaç Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu"; }
  .template-refactor[data-title="residenceDailyCreditInterest"]::before { content: "Konut Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu"; }
  .template-refactor[data-title="vehicleDailyCreditInterest"]::before { content: "Taşıt Kredisi Günlük Bankalara Göre Faiz Tablosu Şablonu"; }
  .template-refactor[data-title="heading"]::before { content: "Başlık Şablonu"; }
  .template-refactor[data-title="mostPreferred"]::before { content: "En Çok Tercih Edilen Şablonu"; }
  .template-refactor[data-title="mostPreferredCreditCard"]::before { content: "En Çok Tercih Edilen Kredi Kartları Şablonu"; }
  .template-refactor[data-title="sticky-main"]::before { content: "Genel Sticky Şablonu"; }
  .template-refactor[data-title="sticky-energy"]::before { content: "Enerji Sticky Şablonu"; }
  .template-refactor[data-title="sticky-finance"]::before { content: "Kredi Sticky Şablonu"; }
  .template-refactor[data-title="sticky-insurance"]::before { content: "Sigorta Sticky Şablonu"; }
  .template-refactor[data-title="kwh-calculate-bill-usage"]::before { content: "Kwh Hesapla"; }
  .template-refactor[data-title="car-tax-calculate"]::before { content: "ÖTV-KDV Hesapla"; }
  .template-refactor[data-title="cta-energy"]::before { content: "CTA Enerji Şablonu"; }
  .template-refactor[data-title="cta-finance"]::before { content: "CTA Kredi Şablonu"; }
  .template-refactor[data-title="cta-insurance"]::before { content: "CTA Sigorta Şablonu"; }
  .template-refactor[data-title="highlighting"]::before { content: "Vurgulayıcı Başlık Şablonu"; }
  .template-refactor[data-title="accordion"]::before { content: "Akordiyon Alan Şablonu"; }
  .template-refactor[data-title="accordion-item"]::before { content: "Akordiyon Nesnesi"; }
  .template-refactor[data-title="list"]::before { content: "İkonlu List"; }
  .template-refactor[data-title="tab-panel"]::before { content: "Tab Panel"; }
  .template-refactor[data-title="tab-title"]::before { content: "Tab Başlık"; }
  .template-refactor[data-title="tab-detail"]::before { content: "Tab Detay"; }
  .template-refactor[data-title="subpages"]::before { content: "Alt Sayfa Şablonu"; }
  .template-refactor[data-title="comparison-corporate"]::before { content: "İş Yeri Karşılaştırma Tablosu"; }
  .template-refactor[data-title="comparison-industry"]::before { content: "Sanayi Karşılaştırma Tablosu"; }
  .template-refactor[data-title="line-chart"]::before { content: "Line Chart"; }
  .template-refactor[data-title="bar-chart"]::before { content: "Bar Chart"; }
  .template-refactor[data-title="pie-chart"]::before { content: "Pie Chart"; }
  .template-refactor[data-title="campaign"]::before { content: "Kampanya Şablonu"; }
  .template-refactor[data-title="deposit-calculate"]::before { content: "Mevduat Hesaplama Şablonu"; }
  .template-refactor[data-title="create-loan-payment-plan"]::before { content: "Kredi Ödeme Planı Oluşturma Aracı Şablonu"; }

  .template-content {
    position: relative;
    
    overflow-x: auto;

    margin-bottom: 8px;
    padding: 4px;

    min-height: 50px;
    
    background: ${colors.white};
    border: 1px solid ${colors.ghostWhiteBorder};
    border-radius: 3px;
  }

  .template-content::before {
    position: absolute;
    right: 0px;
    top: -25px;

    height: 24px;
    
    background: transparent;
    
    font-size: 12px;
    line-height: 24px;
    font-weight: bold;
    color: ${colors.white};
  }

  .template-content-inside {
    overflow: hidden;
    position: relative;

    margin-bottom: 8px;
    padding: 4px;

    min-height: 50px;
    
    background: ${colors.white};
    border: 1px solid ${colors.selectBorder};
    border-radius: 3px;
  }

  .template-content-inside::before {
    position: absolute;
    right: 0px;
    top: 0px;

    padding: 0 12px;

    height: 22px;
    
    background: ${colors.echoBlue};
    border-radius: 0 0 0 3px;
    
    font-size: 12px;
    line-height: 22px;
    font-weight: bold;
    color: ${colors.white};
  }

  [data-template='default']::before { content: "Varsayılan Şablon"; }
  [data-template='formula']::before { content: "Formül Şablonu"; }
  [data-template='heading']::before { content: "Başlık Şablonu"; }
  [data-template='mostPreferred']::before { content: "En Çok Tercih Edilen Şablonu"; }
  [data-template='mostPreferredCreditCard']::before { content: "En Çok Tercih Edilen Kredi Kartları Şablonu"; }
  [data-title="sticky-main"]::before { content: "Genel Sticky Şablonu"; }
  [data-title="sticky-energy"]::before { content: "Enerji Sticky Şablonu"; }
  [data-title="sticky-finance"]::before { content: "Kredi Sticky Şablonu"; }
  [data-title="sticky-insurance"]::before { content: "Sigorta Sticky Şablonu"; }
  [data-template='kwh-calculate-bill-usage']::before { content: "Kwh Hesapla"; }
  [data-template='car-tax-calculate']::before { content: "ÖTV-KDV Hesapla"; }
  [data-template='cta-energy']::before { content: "CTA Enerji Şablonu"; }
  [data-template='cta-finance']::before { content: "CTA Kredi Şablonu"; }
  [data-template='cta-insurance']::before { content: "CTA Sigorta Şablonu"; }
  [data-template='highlighting']::before { content: "Vurgulayıcı Başlık Şablonu"; }
  [data-template='accordion']::before { content: "Akordiyon Alan Şablonu"; }
  [data-template='accordion-item']::before { content: "Akordiyon Nesnesi"; }
  [data-template='list']::before { content: "İkonlu List"; }
  [data-template='tab-panel']::before { content: "Tab Panel"; }
  [data-template='tab-title']::before { content: "Tab Başlık"; }
  [data-template='tab-detail']::before { content: "Tab Detay"; }
  [data-template='tab-title-area']::before { content: "Tab Başlık Ekleyiniz."; }
  [data-template='tab-detail-area']::before { content: "Tab Detay Ekleyiniz."; }
  [data-template='subpages']::before { content: "Alt Sayfa Şablonu"; }
  [data-template='comparison-corporate']::before { content: "İş Yeri Karşılaştırma Tablosu"; }
  [data-template='comparison-industry']::before { content: "Sanayi Karşılaştırma Tablosu"; }
  [data-template='line-chart']::before { content: "Line Chart"; }
  [data-template='bar-chart']::before { content: "Bar Chart"; }
  [data-template='pie-chart']::before { content: "Pie Chart"; }
  [data-template='campaign']::before { content: "Kampanya Şablonu"; }
  [data-template='deposit-calculate']::before { content: "Mevduat Hesaplama Şablonu"; }
  [data-template='create-loan-payment-plan']::before { content: "Kredi Ödeme Planı Oluşturma Aracı Şablonu"; }

  [data-template='tab-title-area'],[data-template='tab-detail-area'] {
    margin: 10px 0px;
  }

  [data-template='list'] {
    list-style: none;
    margin: 0;
    padding: 5px 45px;
  }
  
  [data-template='list'] li {
    position: relative;
    margin: 20px 0px;
  }

  [data-template="list"] li:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -25px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto;
  }

  [data-template="list"] li.-tick:before { background-image: url('${blobUrl}/assets/svg/list-tick.svg'); }
  [data-template="list"] li.-star:before { background-image: url('${blobUrl}/assets/svg/list-star.svg'); }
  [data-template="list"] li.-attention:before { background-image: url('${blobUrl}/assets/svg/list-attention.svg'); }
  [data-template="list"] li.-question:before { background-image: url('${blobUrl}/assets/svg/list-question.svg'); }
  [data-template="list"] li.-active:before { background-image: url('${blobUrl}/assets/svg/list-active.svg'); }
  [data-template="list"] li.-inactive:before { background-image: url('${blobUrl}/assets/svg/list-inactive.svg'); }

  [data-template='cta-energy'],
  [data-template='cta-finance],
  [data-template='cta-insurance]
  {
    display:flex;
    align-items:center;
    flex-direction: row;
  }

  [data-template='cta-energy'],
  [data-template='cta-finance],
  [data-template='cta-insurance] img{
    margin:10px;
    max-width:150px;
    max-height:150px;
  }
`
