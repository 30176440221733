import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Button, message, Divider, Checkbox, Select, Switch } from 'antd'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import UserService from './UserService'
import PhoneNumber from '../../components/PhoneNumber'
import { getRoles } from './role-service'

const userService = new UserService()
const { Option } = Select

export default function UserEdit({ onSuccess, model, suppliers }) {
  const [ready, setReady] = useState(true)
  const [existsMail, setExistsMail] = useState(null)
  const [roles, setRoles] = useState([])
  const [formRef] = Form.useForm()

  useEffect(() => {
    getRoles().then((x) => setRoles(x.data))
  }, [])

  useEffect(() => {
    if (!model) formRef.resetFields()
    else formRef.setFieldsValue({ ...model, supplierId: model.companyId })
  }, [model])

  const submit = async (values) => {
    let _model = { ...values, id: model?.id }
    setReady(false)
    var request = model ? userService.update(_model) : userService.register(_model)
    await request
      .then(() => {
        message.success('işlem başarıyla tamamlandı')
        onSuccess({ mailAddress: _model.mailAddress })
        formRef.resetFields()
      })
      .catch((x) => {
        let error = x.response?.data.errors.find(
          (y) => y.code === 'UserAlreadyExistsException'
        )
        if (error) {
          message.warning('E-posta adresi kullanılıyor')
          setExistsMail(error?.detail?.mailAddress)
          return formRef.validateFields()
        }
        message.error(
          'Kayıt sırasında bazı sorunlar yaşadık. Lütfen daha sonra tekrar deneyin.'
        )
      })
      .finally(() => setReady(true))
  }
  return (
    <Form form={formRef} layout="vertical" onFinish={submit} size="large" hideRequiredMark>
      <Row gutter={[16, 0]}>
        {model !== null && <Col span={24} style={{marginBottom: "50px"}}>
          <IsActiveSwicth
            name="isActive"
            label="Aktiflik Durumu:"
            valuePropName="checked">
            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
          </IsActiveSwicth>
        </Col>}
        <Col span={12}>
          <Form.Item
            label="Ad"
            name="firstName"
            rules={[
              { required: true, message: 'Lütfen adınızı girin' },
              { min: 2, message: 'Çok kısa' },
            ]}
            hasFeedback
          >
            <Input placeholder="Jülide" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Soyad"
            name="lastName"
            rules={[
              { required: true, message: 'Lütfen soyadınızı girin' },
              { min: 2, message: 'Çok kısa' },
            ]}
            hasFeedback
          >
            <Input placeholder="Deniz" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label="E-posta adresi"
            name="email"
            rules={[
              { required: true, message: 'Lütfen e-posta adresinizi girin' },
              { type: 'email', message: 'Geçersiz e-posta adresi' },
              {
                validator: (rule, value) => {
                  if (value === existsMail)
                    return Promise.reject('Bu mail adresi zaten kullanılmaktadır.')
                  return Promise.resolve()
                },
              },
            ]}
            hasFeedback
          >
            <Input type="email" placeholder="julide.deniz@shipfingo.com" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <PhoneNumber />
        </Col>
        <Col span={24}>
          <Form.Item name="supplierId" label="Portal rolü">
            <Select
              notFoundContent="Aradağınız kritere uygun tedarikçi bulunamadı"
              placeholder="Tedarikçi seç"
            >
              <Option key="d" value={0}>
                Greysis Personeli
              </Option>
              {suppliers &&
                suppliers.map((x, i) => (
                  <Option key={i} value={x.id}>
                    {x.brand}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider>Roller</Divider>
          <Form.Item name="roles" rules={[
            { required: true, message: 'Lütfen yetki seçin' }
          ]}>
            <Checkbox.Group
              options={roles?.map((x) => {
                return { label: x.name, value: x.code }
              })}
            />
          </Form.Item>
        </Col>
        {!model && (
          <Col span={24}>
            <Form.Item
              label="Parola"
              name="password"
              rules={[
                { required: true, message: 'Lütfen şifrenizi girin' },
                { min: 6, message: 'Şifre en az 6 karakterli olmalıdır' }
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <Button
            type="primary"
            style={{ width: '100%', marginTop: 20 }}
            onClick={() => formRef.submit()}
            disabled={!ready}
            loading={!ready}
          >
            Kaydet
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

export const IsActiveSwicth = styled(Form.Item)`
  position: absolute;
  right: 30px;
  top: 10px;
  white-space: pre;
  width: 170px;

  * {
    display: initial;
    max-width: 100px;
    flex: none;
  }

  .ant-form-item-control {
    margin: 0 10px;
  }

  .ant-col:last-child {
    width: 50px;
  }

  .ant-col {
    float: left;
    width: auto;
  }

  button {
    width: 50px;
  }
`