import React from 'react'
import { Image } from 'antd'
import styled from 'styled-components'
import colors from '../../styles/colors'

export default function FileBox({ src, onVisibleChange, fileData, title }) {
  return (
    <Box>
      <Image
        title={title}
        width={200}
        fileData={fileData}
        src={src}
        preview={{ visible: false}}
        onClick={onVisibleChange}
      />
      <FileName>{title}</FileName>
    </Box>
  )
}


const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;

  margin: 4px ;
  padding: 4px;

  width: 100%;
  max-width: 210px;
  height: auto;
  min-height: 160px;

  background: ${colors.white};
  border-radius: 4px;
  border: 1px solid ${colors.ghostBorder};

  div.ant-image {
    overflow: hidden;
    min-height: 120px;
    max-height: 120px;
  }
`;
const FileName = styled.p`
  overflow: hidden;
  display: inline-block;


  margin: 0;
  padding: 4px 6px;

  max-width: 200px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;

  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
`
