import React, { useState, useEffect } from 'react'
import { Row, Col, message } from "antd"
import { FolderOutlined } from "@ant-design/icons";

import StorageDirectories from "./StorageDirectories"
import StorageFiles from "./StorageFiles"
import StorageUpload from "./StorageUpload"

import { blobUrl } from "../../configs/origins";
import StorageService from "./StorageService";
import storageFileTypes from './storage-file-types';

const storageService = new StorageService();

export default function StoragePanel({ pageType, setSelectedImage }) {
    const [ready, setReady] = useState(false);
    const [directoryPath, setDirectoryPath] = useState("/");
    const [directoryData, setDirectoryData] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [visibleUpload, setVisibleUpload] = useState(false);

    useEffect(() => {
        refreshStorage();
    }, []);

    function refreshStorage() {
        setVisibleUpload(false);
        getDirectories();
        getFiles(directoryPath);
    }

    function directoryTree(data) {
        return data?.map((x => {
            return {
                title: x.name,
                key: x.path,
                children: x.storages?.length > 0 ? directoryTree(x.storages) : null,
                icon: <FolderOutlined />
            }
        }))
    }

    function getDirectories() {
        setReady(false);
        storageService.getDirectories()
            .then(res => {
                let data = [{ title: "/", key: "/", children: directoryTree(res?.data), icon: <FolderOutlined /> }];
                setDirectoryData(data);

            }).catch(() => {
                message.error("Klasörler yüklenemedi.")
            })
            .finally(() => {
                setReady(true);
            });
    }

    function getFiles(path) {
        setReady(false);
        storageService.getFiles({ path })
            .then(res => {
                let fileData = res?.data?.map((x) => {
                    return {
                        name: x.name,
                        uid: x.id,
                        previewUrl: storageFileTypes.find(y => x.name?.endsWith(y.extension))?.icon || `${blobUrl()}${x.path}`,
                        url: `${blobUrl()}${x.path}`,
                        isDocument: storageFileTypes.some(y => x.name?.endsWith(y.extension)),
                        status: "done",
                        description: x.description,
                        title: x.title
                    }
                });
                setFileData(fileData);
            }).catch((x) => {
                message.error("Dosyalar yüklenemedi.")
            })
            .finally(() => {
                setReady(true);
            });
    }

    const onSelect = (e) => {
        var path = e[e.length -1];
        getFiles(path);
        setDirectoryPath(path);
    };

    return <>
        <Row gutter={[16, 16]} style={{ padding: (pageType !== "page" ? 16 : 0) }}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
                <StorageDirectories
                    onSelect={onSelect}
                    directoryData={directoryData}
                    ready={ready} />

            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                <StorageFiles
                    fileData={fileData}
                    directoryPath={directoryPath === "" ? "/" : directoryPath}
                    refreshStorage={refreshStorage}
                    visibleUpload={() => setVisibleUpload(true)}
                    ready={ready}
                    pageType={pageType}
                    setSelectedImage={setSelectedImage} />

            </Col>
        </Row>

        <StorageUpload
            directoryPath={directoryPath}
            visible={visibleUpload}
            onClose={() => setVisibleUpload(false)}
            refreshStorage={refreshStorage} />
    </>
}
