import { Form, Input, Row, Col, Button } from "antd";
import { SaveOutlined } from '@ant-design/icons'

const { TextArea } = Input;

export default function ScheduledSmsTest({ formRef, onSubmit, ready }) {
    return <>
        <Form form={formRef} layout="vertical" onFinish={onSubmit} size="large">
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="phones"
                        label="Gönderilecek Telefon Numaraları (her satıra bir adet)"
                        rules={[{ required: true, message: 'Lütfen telefon numarası yazınız.' }]}
                    >
                        <TextArea />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Button htmlType="submit" type="primary" style={{ width: "100%", marginTop: 20 }} onClick={() => formRef.submit()} disabled={!ready} loading={!ready}><SaveOutlined />Kaydet</Button>
                </Col>
            </Row>
        </Form>
    </>
}