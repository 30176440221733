import { Popconfirm } from 'antd'
import Button from '../../components/Button'
import { Link } from "react-router-dom"
import { EditOutlined, CheckCircleOutlined, DeleteOutlined, CloseCircleOutlined } from '@ant-design/icons';
import styled, { css } from 'styled-components'

export default function userColumns(edit, remove) {
  return [
    {
      title: 'Adı',
      width: 120,
      dataIndex: 'firstName',
    },
    {
      title: 'Soyadı',
      width: 120,
      dataIndex: 'lastName',
    },
    {
      title: 'İletişim',
      width: 130,
      children: [
        {
          title: 'E-mail',
          dataIndex: 'email',
        },
        {
          title: 'Telefon',
          dataIndex: 'phone',
        },
      ],
    },
    {
      title: 'İşlemler',
      children: [
        {
          title: 'Aktif / Pasif',
          dataIndex: 'isActive',
          width: 110,
          align: 'center',
          render: (v, row) => <IsActiveIcon to="#" active="v">{v ? <CheckCircleOutlined /> : <CloseCircleOutlined />}</IsActiveIcon>
        },
        {
          title: 'Aksiyonlar',
          align: 'center',
          width: 110,
          fixed: 'right',
          render: (v, row) => (
            <>
              <Button key="edit" type="primary" onClick={() => edit(row)}>
                <EditOutlined />
              </Button>
              <Popconfirm
                key="delete"
                title={`Kullanıcıyı silmek istediğinizden emin misiniz?`}
                onConfirm={() => remove(row)}
                okText="Evet"
                cancelText="Hayır"
              >
                <Button type="danger">
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          ),
        },
      ],
    },
  ]
}

export const IsActiveIcon = styled(Link)`
    .anticon{
        font-size:25px;
    }
    ${(props) => props.active && css`
        color:#5ec488;
        &:hover{
            color:#5ec488;
        }
    `}
    ${(props) => !props.active && css`
        color:#e7408c;
        &:hover{
            color:#e7408c;
        }
    `}
`