const colors = {
  white: '#fff',
  blackRussian: '#1D1D1B',
  bunting: '#29304B',
  sapphire: '#081F50',
  sapphireLight: '#0A2359',
  eclips: '#383838',
  jacksonsPurple: '#3B3B78',
  smalt: '#00319B',
  ruby: '#E61974',
  violetRed: '#E7408C',
  violetRedHover: '#FF539F',
  lavender: '#EDEFFB',
  ghostWhite: '#F4F6FD',
  aliceBlue: '#ECF4FF',
  aliceBlueBorder: '#D4E2F5',
  stromGrey: '#767C94',
  manatee: '#9196A8',
  manateeLight: '#7F8496',
  greyChateau: '#A0A1A2',
  solitude: '#F2F5FB',
  solitudeDark: '#E8ECF2',
  solitudeBorder: '#CAD9F7',
  solitudeLight: '#F0F3F8',
  lavenderDark: '#EDEDF3',
  lavenderDarkBorder: 'D4D4EE',
  royalBlue: '#3E73DF',
  ceruleanBlue: '#245ABF',
  pacificBlue: '#15A2C1',
  havelockBlue: '#5C7DD2',
  toryBlue: '#3D5492',
  echoBlue: '#A8B8DA',
  echoBlueDark: '#A5AABF',
  blueZodiac: '#3D435C',
  shipCove: '#7B819A',
  slateGrey: '#718093',
  chambray: '#3C5475',
  chambrayDark: '#535B78',
  lightCyan: '#E1F8FD',
  border: '#D7E1E8',
  borderGost: '#F0F0F0',
  ghostBorder: '#B5C3EC',
  ghostWhiteBorder: '#FAFBFE',
  selectBorder: '#DCDEE8',
  hawkesBlueLight: '#D1D8F1',
  hawkesBlue: '#D2D6E1',
  snow: '#FCFCFC',
  success: '#5EC488',
  wait: '#ECB22E',
  error: '#F45A6A',
  disable: '#AFC0D6',
  uploadBorder: '#74A0FA',
  uploadBg: '#F5F9FF',
  deepLilac: '#9043C1',
  treePoppy: '#DA8436',
  richGold: '#AB491F',
  discount: '#7F87A3',
  discountBorder: '#F46060',
  shark: '#2F3640',
  midnight: '#202B3B',
  slateGreyLight: '#728393',
  ctaHover: '#FA5EA5',
  ctaHoverBorder: '#E61974',
  hastagBg: '#FCEBF3',
  hastagText: '#FF86BD',
};

export default colors;